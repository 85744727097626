import { GET_ALL_CLIENT_MASTER_BY_ID_SUCCESS, GET_ALL_CLIENT_MASTER_SUCCESS, GET_ALL_CLIENT_SUCCESS, GET_CLIENT_DETAILS_SUCCESS, GET_CLIENT_LIST_BYID_SUCCESS } from "../actions/clientAction";

const initialState = {
  GetAllClient: [],
  GetAllClientMaster: [],
  GetAllClientMasterById: [],
  ClientDetials: [],
  CLientListById: [],
  error: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CLIENT_SUCCESS:
      return { ...state, GetAllClient: action.payload.result, error: null };
    case GET_ALL_CLIENT_MASTER_SUCCESS:
      return { ...state, GetAllClientMaster: action.payload.result, error: null };
    case GET_ALL_CLIENT_MASTER_BY_ID_SUCCESS:
      return { ...state, GetAllClientMasterById: action.payload.result, error: null };
    case GET_CLIENT_DETAILS_SUCCESS:
      return { ...state, ClientDetials: action.payload.result, error: null };
    case GET_CLIENT_LIST_BYID_SUCCESS:
      return { ...state, CLientListById: action.payload.result, error: null };
    default:
      return state;
  }
};

export default reducer;
