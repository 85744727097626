
const dashboardMenu = [
  {
    "label": "Dashboard",
    "link": "/dashboard/manage",
    "icon": "ri-pie-chart-2-line"
  },
];


const OpsReviewerMenu = [
  {
    "label": "Timesheets",
    "link": "/OpsReviewer/timesheets",
    "icon": "ri-user-2-line"
  },
  {
    "label": "Past Timesheets",
    "link": "/OpsReviewer/PastTimesheets",
    "icon": "ri-user-2-line"
  },
  {
    "label": "Projection",
    "link": "/OpsReviewer/Projection",
    "icon": "ri-mail-volume-line"
  }
]

const OpsApprovalMenu = [
  {
    "label": "Timesheets",
    "link": "/OpsApproval/timesheets",
    "icon": "ri-user-2-line"
  },
  {
    "label": "Past Timesheets",
    "link": "/OpsApproval/PastTimesheets",
    "icon": "ri-user-2-line"
  },
  {
    "label": "Unbilled Invoices",
    "link": "/OpsApproval/UnbilledInvoices",
    "icon": "ri-mail-volume-line"
  },
  {
    "label": "Unpaid Invoices",
    "link": "/OpsApproval/UnpaidInvoices",
    "icon": "ri-mail-volume-line"
  }
]
const FinanceManagerMenu = [
  {
    "label": "Timesheets",
    "link": "/FinanceManager/timesheets",
    "icon": "ri-user-2-line"
  },
  {
    "label": "Past Timesheets",
    "link": "/FinanceManager/PastTimesheets",
    "icon": "ri-user-2-line"
  },
  {
    "label": "Unbilled Invoices",
    "link": "/FinanceManager/UnbilledInvoices",
    "icon": "ri-mail-volume-line"
  },
  {
    "label": "Unpaid Invoices",
    "link": "/FinanceManager/UnpaidInvoices",
    "icon": "ri-mail-volume-line"
  },
  {
    "label": "Payment Update",
    "link": "/FinanceManager/PaymentUpdate",
    "icon": "ri-mail-volume-line"
  }
]


// const AdminMenu = [
//   {
//     "label": "Reviewer",
//     "link": "/reviewer/manage",
//     "icon": "ri-user-line"
//   }
// ];

// const OperationsReviewerMenu = [];
// const OperationsApproverMenu = [];

const TimesheetMenu = [
  {
    "label": "Timesheet",
    "link": "/timesheet/manage",
    "icon": "ri-calendar-line"
  }
];

const ProjectionMenu = [
  {
    "label": "Projection",
    "link": "/projection/manage",
    "icon": "ri-projector-line"
  }
];

const ApproverMenu = [
  {
    "label": "Approver",
    "link": "/approver/manage",
    "icon": "ri-check-double-line"
  }
];

const FinanaceManagerMenu = [
  {
    "label": "Finanace Manager",
    "link": "/finanaceManager/manage",
    "icon": "ri-user-2-line"
  }
];

const ManagementMenu = [
  {
    "label": "Management",
    "link": "/management/manage",
    "icon": "ri-user-settings-line"
  }
];

const InvoiceTemplateMenu = [
  {
    "label": "Invoice Template",
    "link": "/invoicetemplate/manage",
    "icon": "ri-sticky-note-line"
  }
];

const settingsMenu = [
  {
    "label": "System Settings",
    "link": "/setting/manage",
    "icon": "ri-tools-line"
  }
];


export {
  dashboardMenu,
  OpsReviewerMenu,
  OpsApprovalMenu,
  FinanceManagerMenu,
  TimesheetMenu,
  ProjectionMenu,
  ApproverMenu,
  FinanaceManagerMenu,
  ManagementMenu,
  InvoiceTemplateMenu,
  settingsMenu
};