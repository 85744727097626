import axios from "axios";
import Environment from "../../../core/Environment";

const GetCount = async (data) => {
  try {
    const response = await axios.get(`${Environment.Finance_Url}GetTimeSheetFinaceCount/${data}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const GetFinanceCientList = async (data) => {
  try {
    const response = await axios.get(`${Environment.Finance_Url}GetTimeSheetFinaceMonthWise/${data}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const GetClientById = async (data) => {
  try {
    const response = await axios.get(
      `${Environment.Finance_Url}GetTimeSheetFinaceMonthWiseBYClientId/${data.Id}/${data.SubsId}/${data.monthId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
const AddFinanceTime_Sheet_Status = async (data) => {
  try {
    const response = await axios.post(
      `${Environment.Finance_Url}UpdateFinanceTimeSheetStatus`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  } 
  // finally{
  //   window.location.href="/FinanceManager/manage"; 
  // }
};
const GetpastCount = async (data) => {
  try {
    const response = await axios.get(`${Environment.Finance_Url}GetPastTimeSheetFinaceCount/${data}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const GetPastFinanceCientList = async (data) => {
  try {
    const response = await axios.get(`${Environment.Finance_Url}GetPastTimeSheetFinaceMonthWise/${data}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const GetFinanceInvoiceDetails = async (data) => {
  try {
    const response = await axios.get(
 `${Environment.Finance_Url}GetTimeSheetFinaceInvoiceBYClientId/${data.Id}/${data.SubsId}/${data.monthId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};

const AddInvoiceAPI = async (data) => {
  try {
    debugger
    const response = await axios.post(`${Environment.Finance_Url}CreateInvoice`,data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  } 
};
const GetInvoiceDetailsAPI = async (data) => {
  try {
    const response = await axios.get(
 `${Environment.Finance_Url}GetInvoiceDetails/${data.clientId}/${data.subscriptionId}/${data.months}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const GetInvoiceDataListbyStatusIdAPI = async (data) => {
  try {
    const response = await axios.get(
 `${Environment.Finance_Url}GetInvoicePaidUnPaid/${data.UserId}/${data.StatusId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const GetInvoicePaidDataListbyStatusIdAPI = async (data) => {
  try {
    const response = await axios.get(
 `${Environment.Finance_Url}GetInvoicePaidUnPaid/${data.UserId}/${data.StatusId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const GetUnBilledInvoiceListbyStatusIdAPI = async (data) => {
  try {
    const response = await axios.get(
 `${Environment.Finance_Url}GetUnBilledInvoice/${data.UserId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const UpdateInvoiceAPI = async (data) => {
  try {
    debugger
    const response = await axios.post(`${Environment.Finance_Url}UpdateInvoice`,data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  } 
};
const AddPaymentAPI = async (data) => {
  try {
    debugger
    const response = await axios.post(`${Environment.Finance_Url}AddPayments`,data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  } 
};

export default {
  GetCount,GetFinanceCientList,GetClientById,AddFinanceTime_Sheet_Status,GetpastCount,GetPastFinanceCientList,GetFinanceInvoiceDetails,AddInvoiceAPI,
  GetInvoiceDetailsAPI,GetInvoiceDataListbyStatusIdAPI,GetInvoicePaidDataListbyStatusIdAPI,GetUnBilledInvoiceListbyStatusIdAPI,UpdateInvoiceAPI,AddPaymentAPI
};
