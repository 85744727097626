import React from "react";
import Header from "../../layouts/Header";
import { Link } from "react-router-dom";
import Template1 from "./TemplatesDesign/Template-1/template-1";
import Template2 from "./TemplatesDesign/Template-2/template-2";
import Template3 from "./TemplatesDesign/Template-3/template-3";

export default function CreateTemplate() {
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app manage-item-page p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item">
                                <Link to="/invoicetemplate/manage">Invoice Template</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Create Template</li>
                        </ol>
                        <h4 className="main-title mb-0">Template</h4>
                    </div>
                </div>

                {/* TEMPLATE 1 */}
                {/* <Template1 /> */}

                {/* TEMPLATE 2 */}
                {/* <Template2 /> */}


                {/* TEMPLATE 3 */}
                <Template3 />
            </div>
        </React.Fragment>
    )
}