import axios from "axios";
import Environment from "../../../core/Environment";

const GetCount = async (data) => {
  try {
    const response = await axios.get(
      `${Environment.Approver_Url}GetTotalApproverCount/${data}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const GetApproverCientList = async (data) => {
  try {
    const response = await axios.get(
      `${Environment.Approver_Url}GetTimeSheetApproverMonthWise/${data}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const GetClientById = async (data) => {
  try {
    const response = await axios.get(
      `${Environment.Approver_Url}GetApproverTimeSheetDetailsById/${data.Id}/${data.SubsId}/${data.monthId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};

const AddAppRoverTime_Sheet_Status = async (data) => {
  try {
    const response = await axios.post(
      `${Environment.Approver_Url}UpdateApproverTimeSheetStatus`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  } 
 finally {
    window.location.href = "/OpsApproval/timesheets";
  } // 
};

const GetpastCount = async (data) => {
  try {
    const response = await axios.get(
      `${Environment.Approver_Url}GetPastTotalApproverCount/${data}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
const GetpastApproverCientList = async (data) => {
  try {
    const response = await axios.get(
      `${Environment.Approver_Url}GetPastTimeSheetApproverMonthWise/${data}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};

export default {
  GetCount,
  GetApproverCientList,
  GetClientById,
  AddAppRoverTime_Sheet_Status,
  GetpastCount,
  GetpastApproverCientList,
};
