import axios from "axios";
import Environment from "../../../core/Environment";

const GetAllClientsList = async () => {
  try {
    const response = await axios.get(`${Environment.Client_Url}all-clients`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};
const GetAllClientsMasterList = async () => {
  try {
    const response = await axios.get(`${Environment.Client_Url}GetmasterClientList`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};
const GetAllClientsMasterById = async (Id) => {
  try {
    const response = await axios.get(`${Environment.Client_Url}GetmasterClient_By_Id/${Id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};
const AddMaterClients = async (data) => {
  try { 
    const response = await axios.post(`${Environment.Client_Url}AddMasterClient`, data);
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
const AddClients = async (data) => {
  try { 
    const response = await axios.post(`${Environment.Client_Url}AddClient`, data);
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};
const Addimag = async (data) => {
  try {
    // Create a new FormData object
    const formData = new FormData();
    // Append the file to the FormData object
    formData.append('file', data.file); // 'file' should match the IFormFile parameter in your API
    console.log(formData.get('file')); // Check if the file is correctly appended

    // Send a POST request with the parameters in the URL and the file in the body
    const response = await axios.post(
      `${Environment.File_Url}Upload/${data.Address}/${data.Name}/${data.despositionNumber}`, // URL with path params
      formData, // FormData containing the file
      {
        headers: {
          'Content-Type': 'multipart/form-data', // Set Content-Type to multipart/form-data for file upload
        },
      }
    );

    return response.data; // Return response data for further use
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

const GetClientNameDetails = async (Id) => {
  try {
    const response = await axios.get(`${Environment.Client_Url}GetInvoiceTimeSheetData/${Id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};
const GetClientListBYId = async (Id) => {
  try {
    const response = await axios.get(`${Environment.Client_Url}GetClientListById/${Id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error; // Optionally re-throw to handle in the calling component
  }
};

export default {
  GetAllClientsList,
  GetAllClientsMasterList,
  GetAllClientsMasterById,
  AddMaterClients,
  AddClients,
  Addimag,
  GetClientNameDetails,
  GetClientListBYId
};
