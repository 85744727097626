import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getBussinessInfoList,
  getClientDetials,
  getContractTypesById,
  getDayWiseListById,
  getInvoiceDetails,
  getTimeSheetDataInvoiceList,
} from "../../../../application/selectors/indexSelector";
import {
  GetMappedContractTypeById,
  GetTimeSheetDayWiseBYId,
} from "../../../../application/actions/timeSheetAction";
import { Button, Image } from "react-bootstrap";
import { jsPDF } from "jspdf";
import html2pdf from "html2pdf.js";
import logo from "../../../../assets/img/logo/main-logo.png";
import {
  GetBussinessInfo,
  SendMail,
} from "../../../../application/actions/commonAction";
import { GetClientName } from "../../../../application/actions/clientAction";
import {
  GetFinanceTimeSheetInvoice,
  GetInvoice,
  UpdateInvoice,
} from "../../../../application/actions/financeTimeSheetAction";
import { saveAs } from "file-saver"; // Import FileSaver.js
import useAuth from "../../../../hooks/useAuth";

export default function Template4(props) {
  const { setAuth, auth } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const ContractTypes = useSelector(getContractTypesById);
  const Invoicedata = useSelector(getTimeSheetDataInvoiceList);
  const [initialFetchData, setInitialFetchData] = useState(null);
  const [forumla, setFormula] = useState(null);
  const [amount, setAmount] = useState(null);
  const BussinessList = useSelector(getBussinessInfoList);
  const ClientDetails = useSelector(getClientDetials);
  const getInvoiceDetail = useSelector(getInvoiceDetails);

  useEffect(() => {
    debugger;
    if (props.show && props.data.data) {
      setData(props.data?.data?.months || "");

      const data = {
        Id: props?.data?.data?.clientId,
        SubsId: props?.data?.data?.subscriptionId,
        monthId: props?.data?.data?.months,
      };
      const data1={
        months: props?.data?.data?.months,
        clientId: props?.data?.data?.clientId,
        subscriptionId: props?.data?.data?.subscriptionId
      }
     
      setInitialFetchData(data);
      dispatch(GetFinanceTimeSheetInvoice(data));
       dispatch(GetMappedContractTypeById(data));
      dispatch(GetBussinessInfo);
      dispatch(GetClientName(props?.data?.data?.clientId));
      dispatch(GetInvoice(data1));
    }
  }, [props.show, props.data.data]);

  console.log("getInvoiceDetail", getInvoiceDetail);
  useEffect(() => {
    if (props.show) {
      if (props.data.data) {
        switch (props?.data?.data?.contractName) {
          case "Transaction Amount":
            setFormula("transactions");
            setAmount(ContractTypes?.transactionRate);
            break;
          case "FTE Amount":
            setFormula("transactions");
            setAmount(ContractTypes?.fteRate);
            break;
          case "Contingency Amount":
            setFormula("clientCollections");
            break;
          case "flatefee":
            setFormula("flatefee");
            break;
          default:
            setFormula(null);
        }
      }
    }
  }, [
    props.show,
    props.data,
    ContractTypes?.transactionRate,
    ContractTypes?.fteRate,
  ]);
  const today = new Date(); // Get today's date

  // Format the date as "MM, DD, YYYY"
  const formatDate = (date) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return date.toLocaleDateString("en-US", options).replace(/\//g, "/ ");
  };

  // Set the dates
  const invoiceDate = formatDate(today);
  const paymentDueDate = formatDate(
    new Date(today.setMonth(today.getMonth() + 1))
  );

  // const [invoiceData, setInvoiceData] = useState({
  //   invoiceNumber: "01",
  //   invoiceDate: invoiceDate,
  //   paymentDueDate: paymentDueDate,
  //   companyDetails: {
  //     name: BussinessList[0]?.bussinesName,
  //     email: BussinessList[0]?.emailAddress,
  //     address: BussinessList[0]?.address,
  //     phone: BussinessList[0]?.contactNo,
  //   },
  //   billTo: {
  //     name: "Nateria",
  //     address: "Bangalore, India",
  //   },
  //   lineItems: [
  //     {
  //       streamDescription: "FTE Details",
  //       servicePeriod: "2000",
  //       fteCount: 2,
  //       feePerFte: 500,
  //       totalFee: 800,
  //     },
  //   ],
  //   totalAmount: 800,
  //   footerMessage: BussinessList[0]?.footer,
  // });

  const styles = {
    th: {
      border: "1px solid black",
      padding: "8px",
      backgroundColor: "#f2f2f2",
      textAlign: "left",
    },
    td: { border: "1px solid black", padding: "8px" },
  };

  // Function to generate and download PDF
  const generatePDF = () => {
    const InvoiceData = {
      id: getInvoiceDetail[0].id,
      months: props?.data?.data?.months,
      clientId: props?.data?.data?.clientId,
      subscriptionId: props?.data?.data?.subscriptionId,
      billId:"a7a72067-e173-4b0d-bee0-8372da17e586",
    };
    dispatch(UpdateInvoice(InvoiceData));
    navigate("/FinanceManager/PastTimesheets");
  };

  const sendEmails = () => {
    const element = document.getElementById("invoice-content");
    const clientName = ClientDetails[0]?.clienName || "UnknownClient";
    const month = props?.data?.data?.months || "UnknownMonth";
    const fileName = `${clientName}_${month}.pdf`; // Construct the file name
    
    // Use file-saving functionality in the browser, so avoid hardcoding file path
    const emailBody = `
    <html>
        <body>
            <p>Your invoice has been generated</p>
            <br/>
            <strong>Invoice Number:</strong> ${getInvoiceDetail[0]?.invoiceNo || 'Unknown'}<br/>
            <strong>Date:</strong> ${getInvoiceDetail[0]?.createdOn || 'Unknown'}<br/><br/>
            <strong>Regards,</strong><br/>
            Credence Global Solutions
        </body>
    </html>`;

    // Generate and download the PDF using html2pdf
    html2pdf()
      .from(element)
      .set({
        filename: fileName, // Use the constructed file name
        html2canvas: { scale: 2, useCORS: true }, // Enable CORS for external images
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      })
      .save()
      .then(() => {
        // No need to provide hardcoded file path for emails, let the browser handle file saving
        const data = {
          file: `C:/Users/AB0072/Downloads/${clientName}_${month}.pdf`, // Just send the file name (browser will handle it)
          toEmail: "imthiyas.gani@automatebuddy.com",
          CcEmail: "prachi.raut@automatebuddy.com",
          BccEmail: "",
          subject: "Invoice Message",
          body: emailBody,
        };
        //Local
        // fetch("https://localhost:44306/api/Common/sendEmail", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //Live
        fetch("https://arinvoicegateway.cxengine.net/api/Common/sendEmail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            // Check if the response is JSON
            const contentType = response.headers.get("Content-Type");
            if (contentType && contentType.includes("application/json")) {
              return response.json();
            } else {
              return response.text();  // Handle non-JSON responses (e.g., plain text errors)
            }
          })
          .then((result) => {
            console.log("Email sent successfully:", result);
            const InvoiceData = {
                              id: getInvoiceDetail[0].id,
                              months: props?.data?.data?.months,
                              clientId: props?.data?.data?.clientId,
                              subscriptionId: props?.data?.data?.subscriptionId,
                              billId: "f52c7a35-49df-480b-986c-f79198a1d560",
                          };
              
                          console.log("Dispatching invoice update:", InvoiceData);
                          dispatch(UpdateInvoice(InvoiceData));
                          
          })
          .catch((error) => {
            console.error("Error sending email:", error);
          });
      })
      .catch((error) => {
        console.error("Error generating or downloading the PDF:", error);
      });
      navigate("/FinanceManager/PastTimesheets");
};


//   const sendEmails = () => {
//     console.log("Starting email process...");
//     const element = document.getElementById("invoice-content");
//     const clientName = ClientDetails[0]?.clienName || "UnknownClient";
//     const month = props?.data?.data?.months || "UnknownMonth";

//     if (!element) {
//         console.error("Invoice content element not found!");
//         return;
//     }

//     const fileName = `${clientName}_${month}.pdf`;

//     html2pdf()
//         .from(element)
//         .set({
//             filename: fileName,
//             html2canvas: { scale: 2, useCORS: true },
//             jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
//         })
//         .outputPdf('datauristring') // Get the PDF as a base64 string
//         .then((dataUri) => {
//             console.log("Generated PDF Data URI:", dataUri);

//             if (!dataUri) {
//                 throw new Error("Failed to generate PDF Data URI");
//             }

//             const base64Content = dataUri.split(',')[1];
//             if (!base64Content) {
//                 throw new Error("Base64 content is invalid or missing");
//             }

//             const emailData = {
//                 files: [{ fileName, content: base64Content }], // Include base64 and file name
//                 toEmail: "imthiyas.gani@automatebuddy.com",
//                 CcEmail: "prachi.raut@automatebuddy.com",
//                 BccEmail: "",
//                 subject: "Invoice Message",
//             };

//             console.log("Email Data to Send:", emailData);

//             return fetch("https://localhost:44306/api/Common/sendEmail", {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                 },
//                 body: JSON.stringify(emailData),
//             });
//         })
//         .then((response) => {
//             console.log("API Response Status:", response.status);

//             if (response.ok) {
//                 return response.json();
//             } else {
//                 return response.text().then((text) => {
//                     console.error("API Error Response:", text);
//                     throw new Error("Failed to send email");
//                 });
//             }
//         })
//         .then((result) => {
//             console.log("Email API Response:", result);

//             const InvoiceData = {
//                 id: getInvoiceDetail[0].id,
//                 months: props?.data?.data?.months,
//                 clientId: props?.data?.data?.clientId,
//                 subscriptionId: props?.data?.data?.subscriptionId,
//                 billId: "f52c7a35-49df-480b-986c-f79198a1d560",
//             };

//             console.log("Dispatching invoice update:", InvoiceData);
//             dispatch(UpdateInvoice(InvoiceData));
//         })
//         .catch((error) => {
//             console.error("Error in sending email:", error.message);
//         });
// };


  return (
    <>
      {props?.show && (
        <div className="main main-app manage-item-page pt-3 pb-3" style={{paddingLeft: "20px", paddingRight: "30px"}}>
          <div style={{ fontFamily: "Arial, sans-serif", position: "relative" }}>
            <div id="invoice-content">
              {" "}
              {/* Add ID to the wrapper div */}
              <div className="row">
                <div className="col-6">
                  <Image src={logo} className="wt-200 dark-mode-logo" />
                </div>
                <div className="col-6" style={{ textAlign: "end"}}>
                  <h1 style={{marginRight: "20px" }}>INVOICE</h1>
                </div>
              </div>
              <div
                className="row"
                style={{ marginTop: "15px", paddingLeft: "15px" }}
              >
                <div className="col-8">
                  {/* <h4 className="mb-2">Company Details</h4> */}
                  <p className="ms-3">
                    {BussinessList[0]?.emailAddress} <br />
                    {BussinessList[0]?.address} <br />
                    Phone: {BussinessList[0]?.contactNo}
                  </p>
                  <div
                    className="col-3"
                    style={{ marginTop: "25px", paddingLeft: "2px" }}
                  >
                    <h3 className="mb-2">Bill To</h3>
                    <p className="ms-3">
                      {ClientDetails[0]?.clienName} <br />
                      {ClientDetails[0]?.clientEmailAddress} <br />
                      {ClientDetails[0]?.credenceEmailAddress}
                    </p>
                  </div>
                </div>
                <div className="col-4" style={{ textAlign: "start" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      flexWrap: "wrap",
                    }}
                  >
                    <div>
                      <p style={{marginRight: "20px"}}>
                        Invoice #:{" "}
                        <strong> {getInvoiceDetail[0]?.invoiceNo}</strong>
                      </p>
                      <p style={{marginRight: "20px"}}>
                        Invoice Date:{" "}
                        <strong> {getInvoiceDetail[0]?.createdOn}</strong>
                      </p>
                      <p style={{marginRight: "20px"}}>
                        Payment Due Date:{" "}
                        <strong> {getInvoiceDetail[0]?.duedate}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row"
                style={{
                  marginTop: "25px",
                  padding: "0",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                }}
              >
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th style={styles.th}> Description</th>
                      {props?.data?.data?.contractName ===
                        "Contingency Amount" ||
                      props?.data?.data?.contractName === "flatefee" ? (
                        <th style={styles.th}>Client Collections</th>
                      ) : null}

                      {props?.data?.data?.contractName ===
                        "Transaction Amount" ||
                      props?.data?.data?.contractName === "FTE Amount" ? (
                        <>
                          <th style={styles.th}>Transactions</th>
                          <th style={styles.th}>FTE Deployed</th>
                        </>
                      ) : null}

                      <th style={styles.th}>
                        {props?.data?.data?.contractName}
                      </th>
                      <th style={styles.th}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Invoicedata.map((item, index) => (
                      <tr key={index}>
                        <td style={styles.td}>
                          {Invoicedata[0]?.contractName}
                        </td>
                        {props?.data?.data?.contractName ===
                          "Transaction Amount" ||
                        props?.data?.data?.contractName === "FTE Amount" ? (
                          <>
                            <td style={styles.td}>
                              {Invoicedata[0].transactions}
                            </td>
                            <td style={styles.td}>
                              {Invoicedata[0]?.fteDeployed}
                            </td>
                          </>
                        ) : null}

                        {props?.data?.data?.contractName ===
                          "Contingency Amount" ||
                        props?.data?.data?.contractName === "flatefee" ? (
                          <td sstyle={styles.td}>
                            {Invoicedata.clientCollections}
                          </td>
                        ) : null}
                        <td style={styles.td}>{amount}</td>
                        <td style={styles.td}>
                          {Invoicedata[0]?.invoiceAmount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div
                  style={{
                    textAlign: "end",
                    marginTop: "25px",
                    paddingRight: "30px",
                  }}
                >
                  <h3>
                    Total:{" "}
                    <span style={{ backgroundColor: "#e7e6e6" }}>
                      ${Invoicedata[0]?.invoiceAmount}
                    </span>
                  </h3>
                </div>
              </div>
              <div style={{textAlign: "center"}}>
                  <p>{BussinessList[0]?.footer}</p>
                </div>
            </div>

            <div
                className="row"
                style={{ textAlign: "end", marginTop: "25px" }}
              >
                <div className="d-flex gap-2 justify-content-start">
                  <Button
                    type="button"
                    className="text-uppercase"
                    variant="outline-success"
                    onClick={sendEmails}
                  >
                    Send Email
                  </Button>
                  <Button
                    type="button"
                    className="text-uppercase"
                    variant="outline-primary"
                    onClick={generatePDF} // Trigger PDF generation
                  >
                    {" "}
                    UnBilled
                  </Button>
                </div>
              </div>
          </div>
        </div>
      )}
    </>
  );
}
