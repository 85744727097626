export const GET_TIME_SHEET_FINANACE_COUNT = "GET_TIME_SHEET_FINANACE_COUNT";
export const GET_TIME_SHEET_FINANACE_SUCCESS = "GET_TIME_SHEET_FINANACE_SUCCESS";
export const GET_FINANACE_TIME_SHEET_DETAILS = "GET_FINANACE_TIME_SHEET_DETAILS";
export const GET_FINANACE_TIME_SHEET_DETAILS_SUCCESS = "GET_FINANACE_TIME_SHEET_DETAILS_SUCCESS";
export const GET_APP_CLIENTS_List_BY_ID = "GET_APP_CLIENTS_List_BY_ID";
export const ADD_FINANCE_TIME_SHEET_STATUS = "ADD_FINANCE_TIME_SHEET_STATUS";
export const GET_PAST_TIME_SHEET_FINANACE_COUNT = "GET_PAST_TIME_SHEET_FINANACE_COUNT";
export const GET_PAST_TIME_SHEET_FINANACE_SUCCESS = "GET_PAST_TIME_SHEET_FINANACE_SUCCESS";
export const GET_PAST_FINANACE_TIME_SHEET_DETAILS = "GET_PAST_FINANACE_TIME_SHEET_DETAILS";
export const GET_PAST_FINANACE_TIME_SHEET_DETAILS_SUCCESS = "GET_PAST_FINANACE_TIME_SHEET_DETAILS_SUCCESS";
export const GET_FINANACE_TIME_SHEET_Invoice = "GET_FINANACE_TIME_SHEET_Invoice";
export const GET_FINANACE_TIME_SHEET_INVOICE_SUCCESS = "GET_FINANACE_TIME_SHEET_INVOICE_SUCCESS";
export const ADD_INVOICE_DATA = "ADD_INVOICE_DATA";
export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";
export const GET_INVOICE_DATA = "GET_INVOICE_DATA";
export const GET_INVOICE_DATA_SUCCESS = "GET_INVOICE_DATA_SUCCESS";
export const GET_UNBILLED_INVOICE = "GET_UNBILLED_INVOICE";
export const GET_UNBILLED_INVOICE_SUCCESS = "GET_UNBILLED_INVOICE_SUCCESS";
export const UPDATE_INVOICE_DATA = "UPDATE_INVOICE_DATA";
export const ADD_PAYMENT_DATA = "ADD_PAYMENT_DATA";
export const GET_INVOICE_PAID_DATA = "GET_INVOICE_PAID_DATA";
export const GET_INVOICE_PAID_DATA_SUCCESS = "GET_INVOICE_PAID_DATA_SUCCESS";

export const GetTimeSheetFinanceCount = (data) => ({
  type: GET_TIME_SHEET_FINANACE_COUNT,
  payload: data,
});

export const GetTimeSheetFinanceSuccess = (data) => ({
  type: GET_TIME_SHEET_FINANACE_SUCCESS,
  payload: data,
});
export const GetFinanceTimeSheetDetails = (data) => ({
  type: GET_FINANACE_TIME_SHEET_DETAILS,
  payload: data,
});

export const GetFinanceTimeSheetDetailsSuccess = (data) => ({
  type: GET_FINANACE_TIME_SHEET_DETAILS_SUCCESS,
  payload: data,
});


export const GetClientDetailsBYId = (data) => ({
  type: GET_APP_CLIENTS_List_BY_ID,
  payload: data,
});


export const AddFinanceTimeSheetStatus = (data) => ({
  type: ADD_FINANCE_TIME_SHEET_STATUS,
  payload: data,
});


export const GetPastTimeSheetFinanceCount = (data) => ({
  type: GET_PAST_TIME_SHEET_FINANACE_COUNT,
  payload: data,
});

export const GetPastTimeSheetFinanceSuccess = (data) => ({
  type: GET_PAST_TIME_SHEET_FINANACE_SUCCESS,
  payload: data,
});

export const GetPastTFinanceTimeSheetDetails = (data) => ({
  type: GET_PAST_FINANACE_TIME_SHEET_DETAILS,
  payload: data,
});

export const GetPastTFinanceTimeSheetDetailsSuccess = (data) => ({
  type: GET_PAST_FINANACE_TIME_SHEET_DETAILS_SUCCESS,
  payload: data,
});

export const GetFinanceTimeSheetInvoice = (data) => ({
  type: GET_FINANACE_TIME_SHEET_Invoice,
  payload: data,
});

export const GetTFinanceTimeSheetInvoiceSuccess = (data) => ({
  type: GET_FINANACE_TIME_SHEET_INVOICE_SUCCESS,
  payload: data,
});

export const AddInvoice = (data) => ({
  type: ADD_INVOICE_DATA,
  payload: data,
});

export const UpdateInvoice = (data) => ({
  type: UPDATE_INVOICE_DATA,
  payload: data,
});

export const GetInvoice = (data) => ({
  type: GET_INVOICE,
  payload: data,
});

export const GetInvoiceSuccess = (data) => ({
  type: GET_INVOICE_SUCCESS,
  payload: data,
});

export const GetInvoiceData = (data) => ({
  type: GET_INVOICE_DATA,
  payload: data,
});

export const GetInvoiceDataSuccess = (data) => ({
  type: GET_INVOICE_DATA_SUCCESS,
  payload: data,
});

export const GetPaidInvoiceData = (data) => ({
  type: GET_INVOICE_PAID_DATA,
  payload: data,
});

export const GetInvoicePaidDataSuccess = (data) => ({
  type: GET_INVOICE_PAID_DATA_SUCCESS,
  payload: data,
});

export const GetUnbilledInvoice = (data) => ({
  type: GET_UNBILLED_INVOICE,
  payload: data,
});

export const GetUnilledInvoiceSuccess = (data) => ({
  type: GET_UNBILLED_INVOICE_SUCCESS,
  payload: data,
});

export const AddPayment = (data) => ({
  type: ADD_PAYMENT_DATA,
  payload: data,
});