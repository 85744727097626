import ToastifyService from "../../view/_common/ToastifyService";
import { ADD_CLIENT, Add_CLIENT, ADD_IMAGE, ADD_MASTER_CLIENTMASTER, Add_Master_CLIENTMASTER, GET_ALL_CLIENT,  GET_ALL_CLIENT_MASTER,  GET_ALL_CLIENT_MASTER_BY_ID,  GET_CLIENT_LIST_BYID,  GET_CLIENT_NAME,  GetAllClientMasterByIdSuccess,  GetAllClientMasterSuccess,  GetAllClientSuccess, GetCLientDetailsSuccess, GetClientListByIdSuccess } from "../actions/clientAction";

const getData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
    if (action.type === GET_ALL_CLIENT) {
      try {
      const getclientdata = await api.clientAPI.GetAllClientsList();
      dispatch(GetAllClientSuccess(getclientdata));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };
const getMasterData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
    if (action.type === GET_ALL_CLIENT_MASTER) {
      try {
      const getclientmasterdata = await api.clientAPI.GetAllClientsMasterList();
      dispatch(GetAllClientMasterSuccess(getclientmasterdata));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };
const getMasterDataById =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
    if (action.type === GET_ALL_CLIENT_MASTER_BY_ID) {
      try {       
      const getclientmasterdataById = await api.clientAPI.GetAllClientsMasterById(action.payload);
      dispatch(GetAllClientMasterByIdSuccess(getclientmasterdataById));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };

  const Add_Mater_Clients =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
      try {
    if (action.type === ADD_MASTER_CLIENTMASTER) {
      const AddMasterdata = await api.clientAPI.AddMaterClients(action.payload);
      const getclientmasterdata = await api.clientAPI.GetAllClientsMasterList();
      dispatch(GetAllClientMasterSuccess(getclientmasterdata));
    }
  }
  catch (error) {
    if (error.response.data === "Invalid access token or refresh token") {
      localStorage.removeItem("Token");
    }
    }
    next(action);
  };
  const Add_Client =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
      try {
    if (action.type === ADD_CLIENT) {
      const AddMasterdata = await api.clientAPI.AddClients(action.payload);
      if (AddMasterdata.data.statusCode === "201") {
        ToastifyService.data.success("Created!");
      } else {
        ToastifyService.error("Already Exist!");
      }
      const getclientdata = await api.clientAPI.GetAllClientsList();
      dispatch(GetAllClientSuccess(getclientdata));
    }
  }
  catch (error) {
    if (error.response.data === "Invalid access token or refresh token") {
      localStorage.removeItem("Token");
    }
    }
    next(action);
  };
  const Addimages =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
      try {
    if (action.type === ADD_IMAGE) {
      const AddImage = await api.clientAPI.Addimag(action.payload);
      const getclientdata = await api.clientAPI.GetAllClientsList();
      dispatch(GetAllClientSuccess(getclientdata));
    } 
  }
  catch (error) {
    if (error.response.data === "Invalid access token or refresh token") {
      localStorage.removeItem("Token");
    }
    }
    next(action);
  };
  
  const GetClientName =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
      try {
    if (action.type === GET_CLIENT_NAME) {
      const getName = await api.clientAPI.GetClientNameDetails(action.payload);
      dispatch(GetCLientDetailsSuccess(getName));
    } 
  }
  catch (error) {
    if (error.response.data === "Invalid access token or refresh token") {
      localStorage.removeItem("Token");
    }
    }
    next(action);
  };
  const GetClientListBy =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
      try {
    if (action.type === GET_CLIENT_LIST_BYID) {
      const getName = await api.clientAPI.GetClientListBYId(action.payload);
      dispatch(GetClientListByIdSuccess(getName));
    } 
  }
  catch (error) {
    if (error.response.data === "Invalid access token or refresh token") {
      localStorage.removeItem("Token");
    }
    }
    next(action);
  };
export default [getData,getMasterData,getMasterDataById,Add_Mater_Clients,Add_Client,Addimages,GetClientName,GetClientListBy];
