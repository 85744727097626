export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_CLIENT_SUBSCRIPTION = "GET_CLIENT_SUBSCRIPTION";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_CLIENT_SUBSCRIPTION_SUCCESS = "GET_CLIENT_SUBSCRIPTION_SUCCESS";
export const GET_MASTER_SUBSCRIPTION = "GET_MASTER_SUBSCRIPTION";
export const GET_MASTER_SUBSCRIPTION_SUCCESS = "GET_MASTER_SUBSCRIPTION_SUCCESS";
export const Add_Master_SUBSCRIPTION = "Add_Master_SUBSCRIPTION";
export const Add_MASTER_SUBSCRIPTION_SUCCESS = "Add_MASTER_SUBSCRIPTION_SUCCESS";
export const DELETE_Master_SUBSCRIPTION = "DELETE_Master_SUBSCRIPTION";
export const ADD_SUBSCRIPTION = "ADD_SUBSCRIPTION";


export const GetSubscription = {
  type: GET_SUBSCRIPTION
};

export const GetAllSubscriptionSuccess = (data) => ({
  type: GET_SUBSCRIPTION_SUCCESS,
  payload: data
});

export const GetClientSubscription = (data)=>({
  type: GET_CLIENT_SUBSCRIPTION,
  payload:data
});

export const GetAllClientSubscriptionSuccess = (data) => ({
  type: GET_CLIENT_SUBSCRIPTION_SUCCESS,
  payload: data
});

export const GetMasterSub = {
  type: GET_MASTER_SUBSCRIPTION
};

export const GetMasterSubscriptionSuccess = (data) => ({
  type: GET_MASTER_SUBSCRIPTION_SUCCESS,
  payload: data
});

export const AddMasterSub = (data) => ({  
  type: Add_Master_SUBSCRIPTION,
  payload:data
});
export const DeleteMasterSub = (data) => ({  
  type: DELETE_Master_SUBSCRIPTION,
  payload:data
});

export const Add_Subs = (data)=>({
  type: ADD_SUBSCRIPTION,
  payload:data
});