import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import {
  dashboardMenu,
  OpsReviewerMenu,
  OpsApprovalMenu,
  FinanceManagerMenu,
  ManagementMenu,
  InvoiceTemplateMenu,
  settingsMenu,
} from "../data/Menu";
import logo from "../assets/img/logo/main-logo.png";
import { Badge, Image } from "react-bootstrap";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from "../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  getApproverTimeDetailsList,
  getFinanceTimeDetailsList,
  getInvoiceDataListById,
  getInvoicePaidDataListById,
  getpastApproverTimeDetailsList,
  getPastFinanceTimeDetailsList,
  getPastTimeDetailsList,
  getPredictionDeatailsListById,
  getTimeDetailsList,
  getunbilledInvoiceListById,
} from "../application/selectors/indexSelector";
import {
  GetPastTimeSheetDetails,
  GetPredictionDetailsBYId,
  GetTimeSheetDetails,
} from "../application/actions/timeSheetAction";
import {
  GetApproverTimeSheetDetails,
  GetPastApproverTimeSheetDetails,
} from "../application/actions/approverTimeSheetAction";
import {
  GetFinanceTimeSheetDetails,
  GetInvoiceData,
  GetPaidInvoiceData,
  GetPastTFinanceTimeSheetDetails,
  GetUnbilledInvoice,
} from "../application/actions/financeTimeSheetAction";

export default function Sidebar({ role }) {
  const axiosPrivate = useAxiosPrivate();
  const { setAuth, auth } = useAuth();
  const scrollBarRef = useRef(null);
  const toggleFooterMenu = (e) => {
    e.preventDefault();
    let parent = e.target.closest(".sidebar");
    parent.classList.toggle("footer-menu-show");
  };

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <Link to="#" className="sidebar-logo">
          <Image src={logo} className="wt-200 dark-mode-logo" />
        </Link>
      </div>
      <PerfectScrollbar className="sidebar-body" ref={scrollBarRef}>
        <SidebarMenu
          onUpdateSize={() => scrollBarRef.current.updateScroll()}
          role={role}
        />{" "}
        {/* Pass the role prop to SidebarMenu */}
      </PerfectScrollbar>
      <div className="sidebar-footer">
        <div className="sidebar-footer-top">
          <div className="sidebar-footer-thumb">
            <img src={userAvatar} alt="" />
          </div>
          <div className="sidebar-footer-body">
            <h5 className="mb-1 text-dark fw-semibold fs-14">{auth?.name}</h5>
            <p className="fs-sm text-secondary fs-12">{auth?.roles[0]}</p>
          </div>
          <Link onClick={toggleFooterMenu} to="" className="dropdown-link">
            <i className="ri-arrow-down-s-line"></i>
          </Link>
        </div>
        <div className="sidebar-footer-menu">
          <nav className="nav">
            <Link
              to="/account/login"
              onClick={() => {
                sessionStorage.clear();
                setAuth({});
              }}
            >
              <i className="ri-logout-box-r-line"></i> Log Out
            </Link>
          </nav>
        </div>
      </div>
    </div>
  );
}

function SidebarMenu({ role, onUpdateSize }) {
  const [openMenus, setOpenMenus] = useState({});
  const dispatch = useDispatch();
  const { setAuth, auth } = useAuth();

  const TimeSheetList = useSelector(getTimeDetailsList);
  const pastTimeSheetList = useSelector(getPastTimeDetailsList);
  const ApproverTimeSheetList = useSelector(getApproverTimeDetailsList);
  const ApproverPastTimeSheettList = useSelector(
    getpastApproverTimeDetailsList
  );
  const FinanceTimeSheetList = useSelector(getFinanceTimeDetailsList);
  const FinancePastTimeSheettList = useSelector(getPastFinanceTimeDetailsList);
  const Invoicedata = useSelector(getunbilledInvoiceListById);
  const unpaidInvoicedata = useSelector(getInvoiceDataListById);
  const PaidInvoicedata = useSelector(getInvoicePaidDataListById);
  const ProjectList = useSelector(getPredictionDeatailsListById);

  useEffect(() => {
    const data = { UserId: auth.id };
    const data1 = {
      UserId: auth.id,
      StatusId: "9b39de0c-370a-4f03-a4c1-97238000d1c9",
    };
    const data2 = {
      UserId: auth.id,
      StatusId: "a4298b8d-3631-4899-a105-ad7ac94a9ff5",
    };

    dispatch(GetUnbilledInvoice(data));
    dispatch(GetInvoiceData(data2));
    dispatch(GetPaidInvoiceData(data1));
    dispatch(GetTimeSheetDetails(auth.id));
    dispatch(GetPastTimeSheetDetails(auth.id));
    dispatch(GetApproverTimeSheetDetails(auth.id));
    dispatch(GetPastApproverTimeSheetDetails(auth.id));
    dispatch(GetFinanceTimeSheetDetails(auth.id));
    dispatch(GetPastTFinanceTimeSheetDetails(auth.id));
    dispatch(GetPredictionDetailsBYId(auth?.id));
  }, []);

  const OpsReviewerMenu = [
    {
      label: "Timesheets",
      link: "/OpsReviewer/timesheets",
      icon: "ri-user-2-line",
      count: TimeSheetList?.result?.length,
    },
    {
      label: "Past Timesheets",
      link: "/OpsReviewer/PastTimesheets",
      icon: "ri-user-2-line",
      count: pastTimeSheetList?.result?.length,
    },
    {
      label: "Projection",
      link: "/OpsReviewer/Projection",
      icon: "ri-mail-volume-line",
      count: ProjectList?.length,
    },
  ];
  const OpsApprovalMenu = [
    {
      label: "Timesheets",
      link: "/OpsApproval/timesheets",
      icon: "ri-user-2-line",
      count: ApproverTimeSheetList?.length,
    },
    {
      label: "Past Timesheets",
      link: "/OpsApproval/PastTimesheets",
      icon: "ri-user-2-line",
      count: ApproverPastTimeSheettList?.length,
    },
  ];
  const FinanceManagerMenu = [
    {
      label: "Timesheets",
      link: "/FinanceManager/timesheets",
      icon: "ri-user-2-line",
      count: FinanceTimeSheetList?.length,
    },
    {
      label: "Past Timesheets",
      link: "/FinanceManager/PastTimesheets",
      icon: "ri-user-2-line",
      count: FinancePastTimeSheettList?.length,
    },
    {
      label: "Unbilled Invoices",
      link: "/FinanceManager/UnbilledInvoices",
      icon: "ri-mail-volume-line",
      count: Invoicedata?.length,
    },
    {
      label: "Unpaid Invoices",
      link: "/FinanceManager/UnpaidInvoices",
      icon: "ri-mail-volume-line",
      count: unpaidInvoicedata?.length,
    },
    {
      label: "Payment Update",
      link: "/FinanceManager/PaymentUpdate",
      icon: "ri-mail-volume-line",
      count: PaidInvoicedata?.length,
    },
  ];

  const populateMenu = (menuItems) => {
    return (
      <ul className="nav nav-sidebar">
        {menuItems.map((item, index) => {
          const hasSubmenu = item.submenu && item.submenu.length > 0;
          return (
            <li key={index} className={`nav-item ${openMenus[index] ? "show" : ""}`}>
              <NavLink
                to={item.link}
                className={`nav-link position-relative ${hasSubmenu ? "has-sub" : ""}`}
                onClick={hasSubmenu ? () => toggleSubMenu(index) : null}
              >
                <i className={item.icon}></i> <span>{item.label}</span> <Badge bg="primary" pill className="position-absolute end-0">{item.count}</Badge>
              </NavLink>
              {hasSubmenu && openMenus[index] && (
                <nav className="nav nav-sub">
                  {item.submenu.map((subItem, subIndex) => (
                    <NavLink key={subIndex} to={subItem.link} className="nav-sub-link">
                      {subItem.label}
                    </NavLink>
                  ))}
                </nav>
              )}
            </li>
          );
        })}
      </ul>
    );
  };


  const toggleMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-group");
    parent.classList.toggle("show");

    onUpdateSize();
  };

  const toggleSubMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-item");
    let node = parent.parentNode.firstChild;

    while (node) {
      if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("show");
      node = node.nextElementSibling || node.nextSibling;
    }

    parent.classList.toggle("show");

    onUpdateSize();
  };
  return (
    <>
      {role === "Admin" && (
        <div className="nav-group show without-dropdown">
          {populateMenu(dashboardMenu)}
        </div>
      )}
      {role === "Operations Reviewer" && (
        <>
          {/* Check if user roles are allowed */}
          <div className="nav-group show without-dropdown">{populateMenu(OpsReviewerMenu)}</div>
        </>
      )}
      {role === "Operations Approver" && (
        <>
          {/* Check if user roles are allowed */}
          <div className="nav-group show without-dropdown">{populateMenu(OpsApprovalMenu)}</div>
        </>
      )}

      {role === "Finanace Manager" && (
        <>
          {/* Check if user roles are allowed */}
          <div className="nav-group show without-dropdown">{populateMenu(FinanceManagerMenu)}</div>

          {/* <div className="nav-group show without-dropdown">{populateMenu(FinanceManagerMenu)}</div> */}
          <div className="nav-group show without-dropdown">
            {populateMenu(ManagementMenu)}
          </div>
        </>
      )}

      {role === "Admin" && (
        <>
          <div className="nav-group show without-dropdown">
            {populateMenu(InvoiceTemplateMenu)}
          </div>
          <div className="nav-group show without-dropdown">
            {populateMenu(settingsMenu)}
          </div>
        </>
      )}
    </>
  );
}
