// export class Environment {
const live = "https://arinvoicegateway.cxengine.net/api/";
const local = "https://localhost:44306/api/";
const stage = "";
const defaultValue = "00000000-0000-0000-0000-000000000000";
const baseUrl = stage === "dev" ? local : live;
const Client_Url = `${baseUrl}Client/`;
const Common_Url = `${baseUrl}Common/`;
const Subscription_Url = `${baseUrl}Subscription/`;
const Users_Url = `${baseUrl}Users/`;
const TimeSheet_Url = `${baseUrl}TimeSheet/`;
const Approver_Url = `${baseUrl}ApproverTimeSheet/`;
const Finance_Url = `${baseUrl}FinanceManagerTimeSheet/`;
const File_Url = `${baseUrl}File/`;
const Bussiness_Url = `${baseUrl}Bussiness/`;

export default {
  Client_Url,
  Common_Url,
  Subscription_Url,
  defaultValue,
  Users_Url,
  TimeSheet_Url,
  Approver_Url,
  Finance_Url,
  File_Url,
  Bussiness_Url
};
