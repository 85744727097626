export const GET_ALL_CLIENT = "GET_ALL_CLIENT";
export const GET_ALL_CLIENT_SUCCESS = "GET_ALL_CLIENT_SUCCESS";
export const GET_ALL_CLIENT_MASTER = "GET_ALL_CLIENT_MASTER";
export const GET_ALL_CLIENT_MASTER_SUCCESS = "GET_ALL_CLIENT_MASTER_SUCCESS";
export const GET_ALL_CLIENT_MASTER_BY_ID = "GET_ALL_CLIENT_MASTER_BY_ID";
export const GET_ALL_CLIENT_MASTER_BY_ID_SUCCESS = "GET_ALL_CLIENT_MASTER_BY_ID_SUCCESS";
export const ADD_MASTER_CLIENTMASTER = "ADD_MASTER_CLIENTMASTER";
export const ADD_CLIENT = "ADD_CLIENT";
export const ADD_IMAGE = "ADD_IMAGE";
export const GET_CLIENT_NAME = "GET_CLIENT_NAME";
export const GET_CLIENT_DETAILS_SUCCESS = "GET_CLIENT_DETAILS_SUCCESS";
export const GET_CLIENT_LIST_BYID = "GET_CLIENT_LIST_BYID";
export const GET_CLIENT_LIST_BYID_SUCCESS = "GET_CLIENT_LIST_BYID_SUCCESS";

export const GetClientList = {
  type: GET_ALL_CLIENT
};

export const GetAllClientSuccess = (data) => ({
  type: GET_ALL_CLIENT_SUCCESS,
  payload: data
});
export const GetClientMasterList = {
  type: GET_ALL_CLIENT_MASTER
};

export const GetAllClientMasterSuccess = (data) => ({
  type: GET_ALL_CLIENT_MASTER_SUCCESS,
  payload: data
});
export const GetClientMasterById = (data) => ({
  type: GET_ALL_CLIENT_MASTER_BY_ID,
  payload: data
});

export const GetAllClientMasterByIdSuccess = (data) => ({
  type: GET_ALL_CLIENT_MASTER_BY_ID_SUCCESS,
  payload: data
});
export const AddMastercli = (data) => ({  
  type: ADD_MASTER_CLIENTMASTER,
  payload:data
});
export const AddClient = (data) => ({  
  type: ADD_CLIENT,
  payload:data
});
export const AddImage = (data) => ({  
  type: ADD_IMAGE,
  payload:data
});
export const GetClientName = (data) => ({  
  type: GET_CLIENT_NAME,
  payload:data
});
export const GetCLientDetailsSuccess = (data) => ({  
  type: GET_CLIENT_DETAILS_SUCCESS,
  payload:data
});
export const GetClientListById = (data) => ({  
  type: GET_CLIENT_LIST_BYID,
  payload:data
});
export const GetClientListByIdSuccess = (data) => ({  
  type: GET_CLIENT_LIST_BYID_SUCCESS,
  payload:data
});