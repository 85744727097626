import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Card, Row, Col, Form, Button, Badge } from "react-bootstrap";
import { GetClientSubscription } from "../../../../application/actions/subscriptionAction";
import { GetClientList, GetClientListById } from "../../../../application/actions/clientAction";
import useAuth from "../../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllClientList, getClientListBYId, getClientSubscrtiption, getMonthListById, getpastApproverTimeDetailsList, getpastApproverTimeSheetCount } from "../../../../application/selectors/indexSelector";
import { GetPastApproverTimeSheetDetails, GetPastTimeSheetApproverCount } from "../../../../application/actions/approverTimeSheetAction";
import { GetMonths, GetTimeSheetDayWiseBYId } from "../../../../application/actions/timeSheetAction";
import Header from "../../../../layouts/Header";

export default function OpsApprovalPastTimesheet() {
  const { setAuth, auth } = useAuth();
  const [timesheetData, setTimesheetData] = useState(null);
  const dispatch = useDispatch();
  const clientList = useSelector(getClientListBYId);
  const SubsclientList = useSelector(getClientSubscrtiption);
  const [clientId, setClientId] = useState(null);
  const [subsId, setSubsId] = useState(null);
  const [monthId, setMonthsId] = useState(null);
  // const [TimeSheetLi, setTimeSheetList] = useState([]);
  const MonthWiseList = useSelector(getMonthListById);
  const pastCountList = useSelector(getpastApproverTimeSheetCount);
  const ApproverPastTimeSheettList = useSelector(getpastApproverTimeDetailsList);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(GetClientListById(auth?.id));

    dispatch(GetPastTimeSheetApproverCount(auth?.id));
    dispatch(GetPastApproverTimeSheetDetails(auth?.id));
  }, []);

  const handleClientChange = (event) => {
    const selectedClientId = event?.target?.value;
    setClientId(selectedClientId);
    dispatch(GetClientSubscription(selectedClientId));
  };

  const handleMonthsChange = (event) => {
    const MonthID = event.target.value;
    setMonthsId(MonthID);
  };

  const handleSubsChange = async (event) => {
    const data = {
      Id: clientId, // Use the clientId from state
      SubsId: event?.target?.value, // Subscription ID from the event
    };
    setSubsId(event?.target?.value);
    try {
      // Dispatch or update MonthWiseList based on the subscription change
      dispatch(GetMonths(data)); // Assuming GetMonths is the Redux action
    } catch (error) {
      console.error("Error fetching months:", error);
    }
  };

  const HandleSubmit = () => { };

  if (!pastCountList || pastCountList[0]?.totalTimeSheetCount === 0) {
    return <div>Loading...</div>;
  }

  const totalTimeSheetCount = pastCountList[0]?.totalTimeSheetCount ?? 0;
  const totalCompletedCount = pastCountList[0]?.totalCompletedCount ?? 0;
  const totalRejectCount = pastCountList[0]?.totalRejectCount ?? 0;
  const approvedPercent = totalTimeSheetCount > 0 ? (totalCompletedCount / totalTimeSheetCount) * 100 : 0;
  const pendingPercent = totalTimeSheetCount > 0 ? (totalRejectCount / totalTimeSheetCount) * 100 : 0;

  const cardData = [
    {
      label: "Total",
      icon: "ri-functions",
      value: totalTimeSheetCount,
      percent: "100",
      status: "up",
    },
    {
      label: "Completed",
      icon: "ri-shield-check-line",
      value: totalCompletedCount,
      percent: approvedPercent?.toFixed(2),
      status: "up",
    },
    {
      label: "Rejected",
      icon: "ri-time-line",
      value: totalRejectCount,
      percent: pendingPercent?.toFixed(2),
      status: "down",
    },
  ]

  const columns = [
    {
      name: "Client/Subscription",
      selector: (row) => (
        <div>
          <h5 className="fs-14 mb-1">
            {row?.clientName}
          </h5>
        </div>
      ),
    },
    {
      name: "Months",
      selector: (row) => row?.months,
    },
    {
      name: "Approver",
      selector: (row) => (
        <Badge
          bg={
            row?.fieldValue === "Pending"
              ? "primary"
              : row?.fieldValue === "Reject"
                ? "danger"
                : "success"
          }
          pill
        >
          {row?.fieldValue}
        </Badge>
      ),
    },

    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex align-items-center gap-2">
          <Button variant="outline-dark" className="btn-icon" onClick={() => handleMonthSheet(row)}>
            <i className="ri-eye-line"></i>
          </Button>
        </div>
      ),
    },
  ];

  const handleMonthSheet = (row) => {
    setTimesheetData(row); // Update the state with the selected row data
    const data = {
      Id: row.clientId, // Ensure clientId is set
      SubsId: row.subscriptionId, // Ensure subsId is set
      monthId: row.months, // Ensure monthId is set
    };

    dispatch(GetTimeSheetDayWiseBYId(data));
    navigate('/opsApproval/pastTimesheet/timesheet', {
      state: { timesheetData, row },
    });
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Past Timesheet</h4>
          </div>
        </div>
        <Row className="g-3 mb-3">
          {cardData?.map((card, index) => (
            <Col key={index}>
              <Card className="card-one">
                <Card.Body>
                  <Card.Title as="label" className="fs-sm fw-medium mb-1">
                    {card.label}
                  </Card.Title>
                  <h3 className="card-value mb-1">
                    <i className={card?.icon}></i> {card?.value}
                  </h3>
                  <small>
                    <span
                      className={
                        "d-inline-flex text-" +
                        (card?.status === "up" ? "success" : "danger")
                      }
                    >
                      {card?.percent}%{" "}
                      <i
                        className={
                          "ri-arrow-" +
                          (card?.status === "up" ? "up" : "down") +
                          "-line"
                        }
                      ></i>
                    </span>
                  </small>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        <Card className="card-one">
          <Card.Header className="gap-3 justify-content-end">
            <div className="wt-300">
              <Form.Label>Client</Form.Label>
              <Form.Select onChange={handleClientChange}>
                <option>Choose Client</option>
                {clientList &&
                  clientList?.map((client) => (
                    <option key={client?.id} value={client?.id}>
                      {client?.clienName}
                    </option>
                  ))}
              </Form.Select>
            </div>
            <div className="wt-300">
              <Form.Label>Subscription</Form.Label>
              <Form.Select onChange={handleSubsChange}>
                <option>Choose Subscription</option>
                {SubsclientList &&
                  SubsclientList?.map((Subs) => (
                    <option key={Subs?.id} value={Subs?.id}>
                      {Subs?.subsName}
                    </option>
                  ))}
              </Form.Select>
            </div>
            <div className="wt-300">
              <Form.Label>Month</Form.Label>
              <Form.Select onChange={handleMonthsChange}>
                <option>Choose Month</option>
                {MonthWiseList &&
                  MonthWiseList?.map((mon) => (
                    <option
                      key={mon?.formatted_month}
                      value={mon?.formatted_month}
                    >
                      {mon?.formatted_month}
                    </option>
                  ))}
              </Form.Select>
            </div>

            <div>
              <Button
                type="Submit"
                variant="primary"
                className="d-flex align-items-center gap-2 mt-4"
                onClick={HandleSubmit}
              >
                <i className="ri-filter-line fs-18 lh-1"></i>
                <span className="d-sm-inline"> Filter</span>
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <DataTable
              columns={columns}
              data={ApproverPastTimeSheettList}
              fixedHeader
              highlightOnHover
              pagination
              paginationPerPage={10}
            />
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  )
}