// get loading
export const getLoading = (state) => state.loader;


// client list
export const getAllClientList = (state) => state.client.GetAllClient;
export const getAllClientmasterList = (state) => state.client.GetAllClientMaster;
export const getAllClientmasterById = (state) => state.client.GetAllClientMasterById;
export const getClientDetials = (state) => state.client.ClientDetials;
export const getClientListBYId = (state) => state.client.CLientListById;

//common list
export const getCommonListData = (state)=> state.common.commonList;
export const getContractListData = (state)=> state.common.contractList;
export const getBussinessInfoList = (state)=> state.common.BussinessList;

// Subscription list
export const getSubscrtiptionData = (state)=> state.subscription.subcription;
export const getMasterSubscrtiptionData = (state)=> state.subscription.mastersubcriptions;
export const getClientSubscrtiption = (state)=> state.subscription.Clientsubcriptions;


// users 
export const getRolesData = (state)=> state.users.GetAllRoles;
export const getUserGroupData = (state)=> state.users.GetAllUserGroup;
export const getUserDirectoryData = (state)=> state.users.GetAllUserDirectory;
export const getUsersRolelist = (state)=> state.subscription.usersRolelist;

// TimeSheet 
export const getTimeCount = (state)=> state.timeSheet.coutList;
export const getTimeDetailsList = (state)=> state.timeSheet.getTimeSheetDetails;
export const getPastTimeCount = (state)=> state.timeSheet.pastcoutList;
export const getPastTimeDetailsList = (state)=> state.timeSheet.getpastTimeSheetDetails;
export const getMonthListById = (state)=> state.timeSheet.monthsLi;
export const getClientsListById = (state)=> state.timeSheet.ClientListBYId;
export const getDayWiseListById = (state)=> state.timeSheet.DayWiseListBYId;
export const getPredictionDeatailsListById = (state)=> state.timeSheet.PredictionDetailsListBYId;
export const getPredictionDayWiseListById = (state)=> state.timeSheet.PredictionDayWiseListBYId;
export const getShowTimeSheetCreateModal = (state)=> state.timeSheet.showTimeSheetCreateModal;
export const getContractTypesById = (state)=> state.timeSheet.ContractTypesById;

// ApproverTimeSheet
export const getApproverTimeSheetCount =(state) => state.approver.coutList;
export const getApproverTimeDetailsList = (state)=> state.approver.getApproverTimeSheetDetails;
export const getpastApproverTimeSheetCount =(state) => state.approver.pastcoutList;
export const getpastApproverTimeDetailsList = (state)=> state.approver.getPastApproverTimeSheetDetails;

// FinanaceTimeSheet 
export const getFinanceTimeSheetCount =(state) => state.finance.coutList;
export const getFinanceTimeDetailsList = (state)=> state.finance.getFinanaceTimeSheetDetails;
export const getPastFinanceTimeSheetCount =(state) => state.finance.pastcoutList;
export const getPastFinanceTimeDetailsList = (state)=> state.finance.getPastFinanaceTimeSheetDetails;
export const getTimeSheetDataInvoiceList = (state)=> state.finance.getTimeSheetDataInvoice;
export const getInvoiceDetails = (state)=> state.finance.getInvoiceDetail;
export const getInvoiceDataListById = (state)=> state.finance.getInvoiceDataList;
export const getInvoicePaidDataListById = (state)=> state.finance.getInvoicePaidDataList;
export const getunbilledInvoiceListById = (state)=> state.finance.getunbilledInvoiceLi;
