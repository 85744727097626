import React, { useState } from "react";
import { Card, Col, Image, Row, Table, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Template3() {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col md={4}>
                    <Card className="card-one timesheet-view-table">
                        <Card.Body className="overflow-y-auto">
                            <div className="new-template-edit-view">
                                <div className="image-placeholder d-flex align-items-center justify-content-center" onClick={() => document.getElementById('fileInput').click()}>
                                    {selectedImage ? (
                                        <Image src={selectedImage} alt="Uploaded" fluid />
                                    ) : (
                                        <span id="placeholder-text" className="fw-semibold"><i className="ri-upload-line align-middle"></i> Add Image</span>
                                    )}
                                    <Form.Control
                                        type="file"
                                        id="fileInput"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleImageChange}
                                    />
                                </div>
                            </div>

                            <hr />
                            <div className="mb-3">
                                <Form.Label>Stream Description</Form.Label>
                                <Form.Control as="textarea" rows={4} placeholder="Enter Stream Description" />
                            </div>

                            <div className="mb-3">
                                <Form.Label>Service Period</Form.Label>
                                <Form.Control type="text" placeholder="Enter Service Period" />
                            </div>

                            <div className="mb-3">
                                <Form.Label>Collections</Form.Label>
                                <Form.Control type="text" placeholder="Enter Collections" />
                            </div>

                            <div className="mb-3">
                                <Form.Label>Commission</Form.Label>
                                <Form.Control type="text" placeholder="Enter Commission" />
                            </div>

                            <div className="mb-3">
                                <Form.Label>Fee</Form.Label>
                                <Form.Control type="text" placeholder="Enter Fee" />
                            </div>

                            <div>
                                <Form.Label>Total Amount</Form.Label>
                                <Form.Control type="number" placeholder="Enter Total Amount" />
                            </div>

                            <hr />

                            <h3 className="fs-14 mb-3">Company Info</h3>

                            <div className="mb-3">
                                <Form.Label>Number</Form.Label>
                                <Form.Control type="number" placeholder="Enter Number" />
                            </div>

                            <div>
                                <Form.Label>Address</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="Enter Address" />
                            </div>

                            <hr />

                            <h3 className="fs-14 mb-3">Client Info</h3>
                            <div className="mb-3">
                                <Form.Label>Number</Form.Label>
                                <Form.Control type="number" placeholder="Enter Number" />
                            </div>

                            <div>
                                <Form.Label>Address</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder="Enter Address" />
                            </div>
                        </Card.Body>
                        <Card.Footer className="gap-2 d-flex align-items-center justify-content-end">
                            <Link>Save as Drafts</Link>
                            <Button variant="primary">Save template</Button>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col md={8}>
                    <Card className="card-one h-auto">
                        <Card.Body>
                            <div className="template-main-content">
                                <Row>
                                    <Col>
                                        <div className="upload-img-preview mt-3">
                                            {selectedImage ? (
                                                <Image src={selectedImage} alt="Uploaded" fluid />
                                            ) : (
                                                <Image src="https://placehold.co/200x80/png" alt="logo" />
                                            )}
                                        </div>
                                    </Col>
                                    <Col>
                                        <h2 className="fs-30 fw-bold mb-2 text-dark text-end">INVOICE</h2>
                                    </Col>
                                </Row>

                                <div className="mb-4 d-flex justify-content-end">
                                    <div className="w-30">
                                        <Row className="mb-2">
                                            <Col md={6}>
                                                <Form.Label className="mb-0">Invoice # :</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <h5 className="fs-14 fw-bold">1222</h5>
                                            </Col>
                                        </Row>

                                        <Row className="mb-2">
                                            <Col md={6}>
                                                <Form.Label className="mb-0">Invoice Date :</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <h5 className="fs-14 fw-bold">12/11/2024</h5>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col md={6}>
                                                <Form.Label className="mb-0">Pay Due Date :</Form.Label>
                                            </Col>
                                            <Col md={6}>
                                                <h5 className="fs-14 fw-bold">12/11/2024</h5>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <Row className="mb-5">
                                    <Col md={1}>Address :</Col>
                                    <Col>
                                        <div>
                                            <h3 className="fs-14 mb-1">
                                                Innoval Global Solutions 4222 <br />
                                                Trinity Mills, Suite 260 Dallas TX 75287
                                            </h3>
                                            <h3 className="fs-14">Phone: 214-873-6610</h3>
                                        </div>
                                    </Col>

                                </Row>

                                <Table className="mb-0" responsive>
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th>FTE‘s Count</th>
                                            <th>Per FTE</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Innoval Global Solutions(Atik Sheikh) 11/2001</td>
                                            <td>$0.00</td>
                                            <td>$0.00</td>
                                            <td>%0.00</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan={3}>
                                                <span className="me-2 fs-16 fw-bold text-dark">THANK YOU FOR YOUR BUSINESS!</span>
                                            </th>
                                            <th className="text-right">

                                                <span className="fs-16 text-dark">Total : <b>$0.00</b></span>
                                            </th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}
