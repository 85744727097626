import clientAPI from "./clientAPI/client";
import commonAPI from "./commonAPI/common";
import subscriptionAPI from "./subscriptionAPI/subscription";
import usersAPI from "./usersAPI/users";
import timeSheetAPI from "./timeSheetAPI/timesheet";
import approverAPI from "./approverAPI/approver";
import financeAPI from "./financeAPI/finance";
import accountApi from './accountAPI/account';
import BussinessInfoAPI from "./BussinessInfoAPI/BussinessInfo";

export default {
  clientAPI,
  commonAPI,
  subscriptionAPI,
  usersAPI,
  timeSheetAPI,
  approverAPI,
  financeAPI,
  accountApi,
  BussinessInfoAPI
};
