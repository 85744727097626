import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Badge, Button, Card, Form } from "react-bootstrap";
import unbilledInvoiceData from "../../../../Json/Invoice/unbilled-invoice.json";
import Header from "../../../../layouts/Header";
import { getunbilledInvoiceListById } from "../../../../application/selectors/indexSelector";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../../hooks/useAuth";
import { GetUnbilledInvoice } from "../../../../application/actions/financeTimeSheetAction";
import Template4 from "../../../Invoicetemplate/TemplatesDesign/template-4/template-4";

export default function FinanceManagerUnbilledInvoice() {
  const { setAuth, auth } = useAuth();
  const dispatch = useDispatch();
  const Invoicedata = useSelector(getunbilledInvoiceListById);

  const [showCreateTemplate, setShowCreateTemplate] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  useEffect(() => {
    const data = {
      UserId: auth?.id,
    };
    dispatch(GetUnbilledInvoice(data));
  }, []);

  const handelSendEmail = (row) => {
    setSelectedInvoice({ data: row }); // Wrap the row data in an object with a `data` key
    setShowCreateTemplate(true); // Show the Template4 component
};

  const columns = [
    {
      name: "InvoiceNo",
      selector: (row) => row?.invoiceNo,
      sortable: true,
      cell: (row) => (
        <a href={`/invoices/${row?.invoiceNo}`} target="_blank" rel="noopener noreferrer">
          {row?.invoiceNo}
        </a>
      ),
    },
    {
      name: "Client / Subs",
      selector: (row) => row?.clienName,
      sortable: true,
    },
    {
      name: "Month",
      selector: (row) => row?.months,
      sortable: true,
    },
    {
      name: "Invoice Status",
      selector: (row) => (
        <Badge bg={row?.fieldValue === "UnBilled" ? "warning" : "warning"} pill>
          {row?.fieldValue}
        </Badge>
      ),
    },
    {
      name: "Invoice Amount Code",
      selector: (row) => row?.invoiceAmount,
      sortable: true,
    },
    {
      name: "Submission Date",
      selector: (row) => row?.duedate,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex align-items-center gap-2">
          <Button variant="outline-success" onClick={() => handelSendEmail(row)}>
            Send Email
          </Button>
        </div>
      ),
      sortable: true,
    },
  ];

  // SEARCH FILTER TABLE DATA
  const [searchTerm, setSearchTerm] = useState("");
  const filteredUnbilledInvoice = unbilledInvoiceData?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <React.Fragment>
      <Header />
      <>
        {!showCreateTemplate && (
          <div className="main main-app p-3 p-lg-4">
            <div className="d-md-flex align-items-center justify-content-between mb-3">
              <h3 className="main-title mb-0">UnPaid Invoice</h3>
            </div>
            <Card className="card-one">
              <Card.Header className="align-items-center justify-content-between">
                <h4 className="main-title fs-14 mb-0">List of UnPaid Invoice</h4>
                <div className="custom-dropdown-wrapper">
                  <div className="custom-drop-down z-index-2 wt-300">
                    <span className="dropdown-icon bg-white">
                      <i className="ri-search-line"></i>
                    </span>
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </Card.Header>
              <Card.Body>
                {Invoicedata && Invoicedata !== undefined && (
                  <DataTable
                    columns={columns}
                    data={Invoicedata}
                    fixedHeader
                    search={true}
                    highlightOnHover
                    pagination
                  ></DataTable>
                )}
              </Card.Body>
            </Card>
          </div>
        )}
        {showCreateTemplate && (
          <Template4
          show={showCreateTemplate}
          closeFunction={() => setShowCreateTemplate(false)}
          data={selectedInvoice} // Pass the formatted data
      />
      
        )}
      </>
    </React.Fragment>
  );
}
