import { GET_FINANACE_TIME_SHEET_DETAILS_SUCCESS, GET_FINANACE_TIME_SHEET_INVOICE_SUCCESS, GET_INVOICE_DATA_SUCCESS, GET_INVOICE_PAID_DATA_SUCCESS, GET_INVOICE_SUCCESS, GET_PAST_FINANACE_TIME_SHEET_DETAILS_SUCCESS, GET_PAST_TIME_SHEET_FINANACE_SUCCESS, GET_TIME_SHEET_FINANACE_SUCCESS, GET_UNBILLED_INVOICE_SUCCESS } from "../actions/financeTimeSheetAction";

  const initialState = {
    coutList: [],
    getFinanaceTimeSheetDetails:[],
    pastcoutList: [],
    getPastFinanaceTimeSheetDetails:[],
    getTimeSheetDataInvoice:[],
    getInvoiceDetail:[],
    getInvoiceDataList:[],
    getInvoicePaidDataList:[],
    getunbilledInvoiceLi:[],

    error: null,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_TIME_SHEET_FINANACE_SUCCESS:
        return { ...state, coutList: action.payload.result, error: null };
      case GET_FINANACE_TIME_SHEET_DETAILS_SUCCESS:
        return { ...state, getFinanaceTimeSheetDetails: action.payload.result, error: null };
      case GET_PAST_TIME_SHEET_FINANACE_SUCCESS:
        return { ...state, pastcoutList: action.payload.result, error: null };
      case GET_PAST_FINANACE_TIME_SHEET_DETAILS_SUCCESS:
        return { ...state, getPastFinanaceTimeSheetDetails: action.payload.result, error: null };
      case GET_FINANACE_TIME_SHEET_INVOICE_SUCCESS:
        return { ...state, getTimeSheetDataInvoice: action.payload.result, error: null };
      case GET_INVOICE_SUCCESS:
        return { ...state, getInvoiceDetail: action.payload.result, error: null };
      case GET_INVOICE_DATA_SUCCESS:
        return { ...state, getInvoiceDataList: action.payload.result, error: null };
      case GET_INVOICE_PAID_DATA_SUCCESS:
        return { ...state, getInvoicePaidDataList: action.payload.result, error: null };
      case GET_UNBILLED_INVOICE_SUCCESS:
        return { ...state, getunbilledInvoiceLi: action.payload.result, error: null };
      default:
        return state;
    }
  };
  
  export default reducer;
  