import React, { useEffect, useState } from "react";
import Header from "../../../../layouts/Header";
import DataTable from "react-data-table-component";
import { Card, Row, Col, Form, Button, Badge } from "react-bootstrap";
import { GetClientSubscription } from "../../../../application/actions/subscriptionAction";
import { GetClientList, GetClientListById } from "../../../../application/actions/clientAction";
import useAuth from "../../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllClientList, getClientListBYId, getClientSubscrtiption, getMonthListById, getPastFinanceTimeDetailsList, getPastFinanceTimeSheetCount } from "../../../../application/selectors/indexSelector";
import { GetTimeSheetDayWiseBYId } from "../../../../application/actions/timeSheetAction";
import { GetPastTFinanceTimeSheetDetails, GetPastTimeSheetFinanceCount } from "../../../../application/actions/financeTimeSheetAction";


export default function FinanceManagerPastTimesheet() {
  const { setAuth, auth } = useAuth();
  const [timesheetData, setTimesheetData] = useState(null);
  const [weeklyData, setWeeklyData] = useState([]);
  const dispatch = useDispatch();
  const clientList = useSelector(getClientListBYId);
  const SubsclientList = useSelector(getClientSubscrtiption);
  const [clientId, setClientId] = useState(null);
  const [subsId, setSubsId] = useState(null);
  const [monthId, setMonthsId] = useState(null);
  // const [TimeSheetLi, setTimeSheetList] = useState([]);
  const MonthWiseList = useSelector(getMonthListById);
  const pastCountList = useSelector(getPastFinanceTimeSheetCount);
  const FinancePastTimeSheettList = useSelector(getPastFinanceTimeDetailsList);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(GetClientListById(auth?.id));
    dispatch(GetPastTimeSheetFinanceCount(auth?.id));
    dispatch(GetPastTFinanceTimeSheetDetails(auth?.id));
  }, []);

  const handleClientChange = (event) => {
    const selectedClientId = event?.target?.value;
    setClientId(selectedClientId);
    dispatch(GetClientSubscription(selectedClientId));
  };

  const HandleSubmit = () => {

  };


  if (!pastCountList || pastCountList[0]?.totalTimeSheetCount === 0) {
    return <div>Loading...</div>;
  }

  const totalTimeSheetCount =
    pastCountList[0]?.totalTimeSheetCount ?? 0;

  const totalCompletedCount =
    pastCountList[0]?.totalCompletedCount ?? 0;

  const totalRejectCount =
    pastCountList[0]?.totalRejectCount ?? 0;



  const approvedPercent =
    totalTimeSheetCount > 0
      ? (totalCompletedCount / totalTimeSheetCount) * 100
      : 0;
  const pendingPercent =
    totalTimeSheetCount > 0
      ? (totalRejectCount / totalTimeSheetCount) * 100
      : 0;

  const cardData = [
    {
      label: "Total",
      icon: "ri-functions",
      value: totalTimeSheetCount,
      percent: "100",
      status: "up",
    },
    {
      label: "Completed",
      icon: "ri-shield-check-line",
      value: totalCompletedCount,
      percent: approvedPercent?.toFixed(2),
      status: "up",
    },
    {
      label: "Rejected",
      icon: "ri-time-line",
      value: totalRejectCount,
      percent: pendingPercent?.toFixed(2),
      status: "down",
    },
  ]

  const columns = [
    {
      name: "Client/Subscription",
      selector: (row) => (
        <div>
          <h5 className="fs-14 mb-1">
            {row?.clientName}
          </h5>
        </div>
      ),
    },
    {
      name: "Months",
      selector: (row) => row?.months,
    },
    {
      name: "Approver",
      selector: (row) => (
        <Badge
          bg={
            row?.fieldValue === "Pending"
              ? "primary"
              : row?.fieldValue === "Reject"
                ? "danger"
                : "success"
          }
          pill
        >
          {row?.fieldValue}
        </Badge>
      ),
    },

    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex align-items-center gap-2">
          <Button
            variant="outline-dark"
            className="btn-icon"
            onClick={() => handleMonthSheet(row)}
          >
            <i className="ri-eye-line"></i>
          </Button>
        </div>
      ),
    },
  ];
  const handleMonthSheet = (row) => {
    setTimesheetData(row); // Update the state with the selected row data
    const data = {
      Id: row.clientId, // Ensure clientId is set
      SubsId: row.subscriptionId, // Ensure subsId is set
      monthId: row.months, // Ensure monthId is set
    };

    dispatch(GetTimeSheetDayWiseBYId(data));

    navigate('/financeManager/pastTimesheet/timesheet', {
      state: {
        timesheetData,
        row,
      },
    });
  };
  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <h4 className="main-title mb-0">Past Timesheet</h4>
          </div>
        </div>
        <Row className="g-3 mb-3">
          {cardData?.map((card, index) => (
            <Col key={index}>
              <Card className="card-one">
                <Card.Body>
                  <Card.Title as="label" className="fs-sm fw-medium mb-1">
                    {card.label}
                  </Card.Title>
                  <h3 className="card-value mb-1">
                    <i className={card?.icon}></i> {card?.value}
                  </h3>
                  <small>
                    <span
                      className={
                        "d-inline-flex text-" +
                        (card?.status === "up" ? "success" : "danger")
                      }
                    >
                      {card?.percent}%{" "}
                      <i
                        className={
                          "ri-arrow-" +
                          (card?.status === "up" ? "up" : "down") +
                          "-line"
                        }
                      ></i>
                    </span>
                  </small>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>


        <Card className="card-one">
          <Card.Header className="gap-3 justify-content-end">
            <div className="wt-300">
              <Form.Label>Status</Form.Label>
              <Form.Select>
                <option>All</option>
                <option>Completed</option>
                <option>Rejected</option>
              </Form.Select>
            </div>
            <div className="wt-300">
              <Form.Label>Client</Form.Label>
              <Form.Select onChange={handleClientChange}>
                <option>Choose Client</option>
                {clientList &&
                  clientList?.map((client) => (
                    <option key={client?.id} value={client?.id}>
                      {client?.clienName}
                    </option>
                  ))}
              </Form.Select>
            </div>

            <div>
              <Button
                type="Submit"
                variant="primary"
                className="d-flex align-items-center gap-2 mt-4"
                onClick={HandleSubmit}
              >
                <i className="ri-filter-line fs-18 lh-1"></i>
                <span className="d-sm-inline"> Filter</span>
              </Button>
            </div>
          </Card.Header>
          <Card.Body>
            <DataTable
              columns={columns}
              data={FinancePastTimeSheettList}
              fixedHeader
              highlightOnHover
              pagination
              paginationPerPage={10}
            />
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  )
}