import { GET_ALL_ROLES,  GetAllRolesSuccess,GET_ALL_USERGROUP, GetAllUserGroupSuccess, GET_ALL_USERDIRECTORY, GetAllUserDirectorySuccess, ADD_USER_DIRECTORY, GET_USERS_LIST_BY_ID, GetUsersListByIdSuccess, GET_USERS_LIST_BY_ID_SUCCESS_ID_2, GET_USERS_LIST_BY_ID_SUCCESS_ID_6, GET_USERS_LIST_BY_ID_SUCCESS_ID_3 } from "../actions/usersAction";

const getRoleData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {

    if (action.type === GET_ALL_ROLES) {
      try {
      const getrolesdata = await api.usersAPI.GetAllRolesList();
    
      dispatch(GetAllRolesSuccess(getrolesdata));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };
  const getUserGroupData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
    if (action.type === GET_ALL_USERGROUP) {
      try {
      const getUsergroupdata = await api.usersAPI.GetAllUsersGroupList();
      dispatch(GetAllUserGroupSuccess(getUsergroupdata));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };
  const getUserDirectoryData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
    if (action.type === GET_ALL_USERDIRECTORY) {
      try {
      const getUserdirectorydata = await api.usersAPI.GetAllUsersDirectoryList();
      dispatch(GetAllUserDirectorySuccess(getUserdirectorydata));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };
  
  const AddUserDirec =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
    if (action.type === ADD_USER_DIRECTORY) {
      try {
      const AddDirectorydata = await api.usersAPI.AddUserDirectoryApi(action.payload);
      const getUserdirectorydata = await api.usersAPI.GetAllUsersDirectoryList();
      dispatch(GetAllUserDirectorySuccess(getUserdirectorydata));
    }catch (error) {
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
      }
      }
    }
    next(action);
  };
  const GetUsersListById =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
      try {
        if (action.type === GET_USERS_LIST_BY_ID) {
          const getuserslist = await api.usersAPI.GetUsersRolesById(action.payload);

          // Dispatch specific actions based on the ID
          switch (action.payload) {
            case 2:
              dispatch({ type: GET_USERS_LIST_BY_ID_SUCCESS_ID_2, payload: getuserslist });
              break;
            case 6:
              dispatch({ type: GET_USERS_LIST_BY_ID_SUCCESS_ID_6, payload: getuserslist });
              break;
            case 3:
              dispatch({ type: GET_USERS_LIST_BY_ID_SUCCESS_ID_3, payload: getuserslist });
              break;
            default:
              console.warn('Unhandled ID:', action.payload);
          }
        }
      } catch (error) {
        if (error.response?.data === 'Invalid access token or refresh token') {
          localStorage.removeItem('Token');
        } else {
          console.error('Failed to fetch user list:', error);
        }
      }
      next(action);
    };

export default [getRoleData,getUserGroupData,getUserDirectoryData,AddUserDirec,GetUsersListById];
