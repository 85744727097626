import {
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_USERDIRECTORY_SUCCESS,
  GET_ALL_USERGROUP_SUCCESS,
  GET_USERS_LIST_BY_ID_SUCCESS,
  GET_USERS_LIST_BY_ID_SUCCESS_ID_2,
  GET_USERS_LIST_BY_ID_SUCCESS_ID_3,
  GET_USERS_LIST_BY_ID_SUCCESS_ID_6,
} from "../actions/usersAction";

const initialState = {
  GetAllRoles: [],
  GetAllUserGroup: [],
  GetAllUserDirectory: [],
  usersById2: [],
  usersById6: [],
  usersById3: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ROLES_SUCCESS:
      return { ...state, GetAllRoles: action.payload.result, error: null };
    case GET_ALL_USERGROUP_SUCCESS:
      return { ...state, GetAllUserGroup: action.payload.result, error: null };
    case GET_ALL_USERDIRECTORY_SUCCESS:
      return {
        ...state,
        GetAllUserDirectory: action.payload.result,
        error: null,
      };
    case GET_USERS_LIST_BY_ID_SUCCESS_ID_2:
      return { ...state, usersById2: action.payload.result };
    case GET_USERS_LIST_BY_ID_SUCCESS_ID_6:
      return { ...state, usersById6: action.payload.result };
    case GET_USERS_LIST_BY_ID_SUCCESS_ID_3:
      return { ...state, usersById3: action.payload.result };
    default:
      return state;
  }
};

export default reducer;
