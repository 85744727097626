import { Add_Master_SUBSCRIPTION, ADD_SUBSCRIPTION, DELETE_Master_SUBSCRIPTION, GET_CLIENT_SUBSCRIPTION, GET_MASTER_SUBSCRIPTION, GET_SUBSCRIPTION,  GetAllClientSubscriptionSuccess,  GetAllSubscriptionSuccess, GetMasterSubscriptionSuccess } from "../actions/subscriptionAction";

const getData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
      try {
    if (action.type === GET_SUBSCRIPTION) {
      const getsubsdata = await api.subscriptionAPI.GetSubscriptionList();
      dispatch(GetAllSubscriptionSuccess(getsubsdata));
    }
  }
  catch (error) {
    if (error.response.data === "Invalid access token or refresh token") {
      localStorage.removeItem("Token");
    }
    }
    next(action);
  };
const GetSubscriptionList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
      try {
      
    if (action.type === GET_CLIENT_SUBSCRIPTION) {
      const getsubsdata = await api.subscriptionAPI.GetUserSubscriptionId(action.payload);
      dispatch(GetAllClientSubscriptionSuccess(getsubsdata));
    }
  }
  catch (error) {
    if (error.response.data === "Invalid access token or refresh token") {
      localStorage.removeItem("Token");
    }
    }
    next(action);
  };
const GetMater_SubsList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
      try {
      
    if (action.type === GET_MASTER_SUBSCRIPTION) {
      const getMasterdata = await api.subscriptionAPI.GetMaterSubsList();
      dispatch(GetMasterSubscriptionSuccess(getMasterdata));
    }
  }
  catch (error) {
    if (error.response.data === "Invalid access token or refresh token") {
      localStorage.removeItem("Token");
    }
    }
    next(action);
  };

  const Add_Mater_SubsList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
      try {
    if (action.type === Add_Master_SUBSCRIPTION) {
      const AddMasterdata = await api.subscriptionAPI.AddMaterSubscription(action.payload);
      const getMasterdata = await api.subscriptionAPI.GetMaterSubsList();
      dispatch(GetMasterSubscriptionSuccess(getMasterdata));
    }
  }
  catch (error) {
    if (error.response.data === "Invalid access token or refresh token") {
      localStorage.removeItem("Token");
    }
    }
    next(action);
  };
  const Delete_Mater_SubsList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
      try {
      
    if (action.type === DELETE_Master_SUBSCRIPTION) {
      const AddMasterdata = await api.subscriptionAPI.DeleteMaterSubscription(action.payload);
      const getMasterdata = await api.subscriptionAPI.GetMaterSubsList();
      dispatch(GetMasterSubscriptionSuccess(getMasterdata));
    }
  }
  catch (error) {
    if (error.response.data === "Invalid access token or refresh token") {
      localStorage.removeItem("Token");
    }
    }
    next(action);
  };
  const Add_Subscription =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
    async (action) => {
      try {
    if (action.type === ADD_SUBSCRIPTION) {      
      const AddSubs = await api.subscriptionAPI.AddSubscription(action.payload);
      const getsubsdata = await api.subscriptionAPI.GetSubscriptionList();
      dispatch(GetAllSubscriptionSuccess(getsubsdata));
    }
    
  }
  catch (error) {
    if (error.response.data === "Invalid access token or refresh token") {
      localStorage.removeItem("Token");
    }
    }
    next(action);
  };
export default [getData,GetSubscriptionList,GetMater_SubsList,Add_Mater_SubsList,Delete_Mater_SubsList,Add_Subscription];
