import axios from "axios";
import Environment from "../../../core/Environment";

const GetBussinessInfo = async () => {
    
  try {
    const response = await axios.get(`${Environment.Bussiness_Url}GetBussinessInfo`);
    return response.data;
  } catch (error) {
    console.error("Error fetching Common:", error);
    throw error;
  }
};


export default {
    GetBussinessInfo
};
