import React, { useState } from "react";
import { Offcanvas, Button, Form } from "react-bootstrap";
import useAuth from "../../../../hooks/useAuth";
import { useDispatch } from "react-redux";
import { AddPayment } from "../../../../application/actions/financeTimeSheetAction";

export default function AddPaymentDetails(props) {
  debugger;
  const { auth } = useAuth();
  const dispatch = useDispatch();

  const [paidAmount, setPaidAmount] = useState("");
  const [adjustmentAmount, setAdjustmentAmount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [refNo, setRefNo] = useState("");
  const [paidDate, setPaidDate] = useState("");

  const handlePaidAmountChange = (e) => {
    const value = parseFloat(e.target.value) || 0; // Default to 0 if empty
    setPaidAmount(value);

    // Calculate adjustment amount
    const invoiceAmount = props?.data?.invoiceAmount || 0;
    const adjustment = invoiceAmount - value;

    setAdjustmentAmount(adjustment);
  };

  const handleClose = () => {
    setPaidAmount("");
    setAdjustmentAmount("");
    setRemarks("");
    setRefNo("");
    setPaidDate("");
    props.closeFunction(); // Call the parent-provided close function
  };

  const getAdjustmentStyle = () => {
    return adjustmentAmount < 0 ? { color: "red" } : { color: "green" };
  };

  const handleSubmit = () => {
    const data = {
      invoiceId: props?.data?.id,
      refNo: refNo,
      invoiceAmount: props?.data?.invoiceAmount,
      paidAmount: paidAmount,
      adjustAmount: Math.abs(adjustmentAmount),
      remarks: remarks,
      paidDate: paidDate,
      createdBy: auth?.id,
      status: "5ed68f5c-db32-4841-8aa1-99b1f8721100",
      UserId: auth?.id,
      StatusId: "a4298b8d-3631-4899-a105-ad7ac94a9ff5",
    };

    dispatch(AddPayment(data));
    handleClose();
  };

  return (
    <>
      <React.Fragment>
        <Offcanvas
          show={props.show}
          onHide={handleClose}
          placement="end"
          className="w-35"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title className="fs-16 text-dark">
              Add Payment Detail
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="mb-3">
              <Form.Label>Client Name / Subscription</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                value={props?.data?.clienName}
                disabled
              />
            </div>
            <div className="mb-3">
              <Form.Label>Invoice No :#</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Ref#"
                value={props?.data?.invoiceNo}
                disabled
              />
            </div>
            <div className="mb-3">
              <Form.Label>Ref No :#</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Ref"
                value={refNo}
                onChange={(e) => setRefNo(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <Form.Label>Paid Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter Paid Date"
                value={paidDate}
                onChange={(e) => setPaidDate(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <Form.Label>Invoice Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Paid Amount"
                value={props?.data?.invoiceAmount}
                disabled
              />
            </div>
            <div className="mb-3">
              <Form.Label>Paid Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Paid Amount"
                value={paidAmount}
                onChange={handlePaidAmountChange}
              />
            </div>

            {paidAmount !== "" && (
              <div className="mb-3">
                <Form.Label>Adjustment Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Adjustment Amount"
                  value={Math.abs(adjustmentAmount)}
                  readOnly
                  style={getAdjustmentStyle()}
                />
              </div>
            )}

            <div className="mb-3">
              <Form.Label>Remark</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter Remark"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>
          </Offcanvas.Body>

          <div className="offcanvas-footer justify-content-start">
            <Button
              type="submit"
              variant="primary"
              className="fs-14 me-2 d-flex align-items-center"
              onClick={handleSubmit}
            >
              <i className="ri-add-line fs-18 lh-1 align-middle"></i>
              <span className="align-middle">Submit</span>
            </Button>
          </div>
        </Offcanvas>
      </React.Fragment>
    </>
  );
}
