import React, { useEffect, useState } from "react";
import paidInvoiceData from "../../../../Json/Invoice/paid-invoice.json";
import { Badge, Card, Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Header from "../../../../layouts/Header";
import { GetInvoiceData, GetPaidInvoiceData } from "../../../../application/actions/financeTimeSheetAction";
import useAuth from "../../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceDataListById, getInvoicePaidDataListById } from "../../../../application/selectors/indexSelector";

export default function FinanceManagerPaymentUpdate() {
    const { setAuth, auth } = useAuth();
    const dispatch = useDispatch();
    const Invoicedata = useSelector(getInvoicePaidDataListById);
  
    useEffect(() => {
      const data = {
        UserId: auth?.id,
        StatusId: "9b39de0c-370a-4f03-a4c1-97238000d1c9",
      };
      debugger;
      dispatch(GetPaidInvoiceData(data));
    }, []);

  console.log("Invoicedata",Invoicedata);
    const columns = [
     
      {
          name: "RefNo",
          selector: (row) => row?.refNo,
          sortable: true,
          cell: (row) => (
            <a href={`/invoices/${row?.refNo}`} target="_blank" rel="noopener noreferrer">
              {row?.refNo}
            </a>
          ),
        },
        
      {
        name: "Client / Subs",
        selector: (row) => row?.clienName,
        sortable: true,
      },
  
      {
        name: "Month",
        selector: (row) => row?.months,
        sortable: true,
      },
      {
        name: "Submission Date",
        selector: (row) => row?.duedate,
        sortable: true,
      },
      {
        name: "Paid Date",
        selector: (row) => row?.paidDate,
        sortable: true,
      },
    
      {
        name: "Invoice Amount",
        selector: (row) => row?.invoiceAmount,
        sortable: true,
      },
      {
        name: "Paid Amount",
        selector: (row) => row?.paidAmout,
        sortable: true,
      },
      {
        name: "Adjustment Amount",
        selector: (row) => row?.adjustAmount,
        sortable: true,
      },
      {
        name: "Reamrks",
        selector: (row) => row?.remarks,
        sortable: true,
      },
      {
        name: "Payment Status",
        selector: (row) => (
          <Badge bg={row?.fieldValue === "UnPaid" ? "warning" : "success"} pill>
            {row?.fieldValue}
          </Badge>
        ),
      },
      ];


    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredPaidInvoice = paidInvoiceData?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );


    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-3">
                    <h3 className="main-title mb-0">Paid Invoice</h3>
                </div>

                <Card className="card-one">
                    <Card.Header className="align-items-center justify-content-between">
                        <h4 className="main-title fs-14 mb-0">List of Paid Invoice</h4>
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-300">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <DataTable
                            columns={columns}
                            data={Invoicedata}
                            fixedHeader
                            search={true}
                            highlightOnHover
                            pagination
                        ></DataTable>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    )
}