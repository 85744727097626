import React, { useState, useEffect } from "react";
import { Button, Form, Offcanvas, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { format, subDays } from "date-fns";
import { AddDayWiseDataById, GetMappedContractTypeById, GetTimeSheetDayWiseBYId } from "../../../../../application/actions/timeSheetAction";
import ToastifyService from "../../../../_common/ToastifyService";
import { useDispatch, useSelector } from "react-redux";
import { getContractTypesById, getDayWiseListById } from "../../../../../application/selectors/indexSelector";
import { useNavigate } from "react-router-dom";
import { AddFinanceTimeSheetStatus } from "../../../../../application/actions/financeTimeSheetAction";

export default function MonthsviewModal(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [gettimeSheetData, setGettimeSheetData] = useState(null);
    const [data, setData] = useState([]);
    const [editId, setEditId] = useState(null);
    const [forumla, setFormula] = useState(null);
    const [amount, setAmount] = useState(null);
    const [editedRow, setEditedRow] = useState({});
    const [editedata, setEditedata] = useState({});
    const [remarks, setRemarks] = useState('');
    const DayWiseTimeSheetList = useSelector(getDayWiseListById);
    const ContractTypes = useSelector(getContractTypesById);
    const [initialFetchData, setInitialFetchData] = useState(null);

    useEffect(() => {
        if (props.show) {
            if (props.data) {
                setGettimeSheetData(props.data);
                setData(props.data.months || "");

                const data = {
                    Id: props?.data?.clientId,
                    SubsId: props?.data?.subscriptionId,
                    monthId: props?.data?.months,
                };
                setInitialFetchData(data);
                dispatch(GetTimeSheetDayWiseBYId(data));
                dispatch(GetMappedContractTypeById(data));
            }
        }
    }, [props.show, props.data]);

    useEffect(() => {
        if (props.show) {
            if (props.data) {
                switch (props?.data?.contractName) {
                    case "Transaction Amount":
                        setFormula("transactions");
                        setAmount(ContractTypes?.transactionRate);
                        break;
                    case "FTE Amount":
                        setFormula("transactions");
                        setAmount(ContractTypes?.fteRate);
                        break;
                    case "Contingency Amount":
                        setFormula("clientCollections");
                        break;
                    case "flatefee":
                        setFormula("flatefee");
                        break;
                    default:
                        setFormula(null);
                }
            }
        }
    }, [
        props.show,
        props.data,
        ContractTypes?.transactionRate,
        ContractTypes?.fteRate,
    ]);


    const handleEditClick = (row) => {
        setEditId(row.id);
        setEditedRow({ ...row });
    };

    const handleSaveClick = async () => {
        const data1 = {
            id: editedRow.id,
            transactions: editedRow.transactions || 0,
            ClientCollections: editedRow.ClientCollections || 0,
            FTEDeployed: editedata.FTEDeployed || 0,
            TransactionAmount: editedata.TransactionAmount || 0,
            FTEAmount: editedata.FTEAmount || 0,
            ContingencyAmount: editedata.ContingencyAmount || 0,
            InvoiceAmount: editedata.invoiceAmount || 0,
        };

        try {
            dispatch(AddDayWiseDataById({ data1, initialFetchData }));
            setEditId(null);
            ToastifyService.success("Updated Timesheet!");
        } catch (error) {
            console.error("Error saving data:", error);
            ToastifyService.error("Failed to update Timesheet!");
        }
    };

    const handleInputChange = (e, field) => {
        const value = e.target.value;

        setEditedRow((prevState) => {
            const updatedRow = { ...prevState, [field]: value };
            let invoiceAmount = updatedRow?.invoiceAmount;
            if (field === "transactions" && forumla === "transactions") {
                if (props?.data?.contractName === "Transaction Amount") {
                    invoiceAmount = value * (2 || 1);
                    setEditedata({ invoiceAmount: invoiceAmount });
                } else if (props?.data?.contractName === "FTE Amount") {
                    const fteDeployed = value / (ContractTypes?.transactionTarget || 1);
                    invoiceAmount = fteDeployed * (amount || 1);
                    updatedRow.fteDeployed = fteDeployed;
                    setEditedata({
                        FTEDeployed: fteDeployed,
                        invoiceAmount: invoiceAmount,
                        FTEAmount: amount,
                    });
                }
            } else if (
                field === "clientCollections" &&
                forumla === "clientCollections"
            ) {
                invoiceAmount = value;
                setEditedata({ invoiceAmount: invoiceAmount });
            } else if (forumla === "flatefee") {
                invoiceAmount = updatedRow.amount;
                setEditedata({ invoiceAmount: invoiceAmount });
            }
            updatedRow.invoiceAmount = invoiceAmount;
            return updatedRow;
        });
    };

    const handleSubmit = () => {
        const data = {
            FinaceRemarks: remarks,
            Approver: 1,
            months: props?.data?.months,
            clientid: props?.data?.clientId,
            subscriptionid: props?.data?.subscriptionId,
        };
        dispatch(AddFinanceTimeSheetStatus(data));
        ToastifyService.success("Operational Finance Accepted Timesheet Successfully Submitted!");
    };

    const handleReject = () => {
        const data = {
            FinaceRemarks: remarks,
            Approver: 0,
            months: props?.data?.months,
            clientid: props?.data?.clientId,
            subscriptionid: props?.data?.subscriptionId,
        };
        dispatch(AddFinanceTimeSheetStatus(data));
        ToastifyService.success("Operational Finance Rejected Timesheet Successfully Submitted!");
    };
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-90">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">
                        Finance manager TimeSheet
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Table className="mb-0" responsive>
                        <thead>
                            <tr>
                                <th>Day</th>
                                {props?.data?.contractName === "Contingency Amount" ||
                                    props?.data?.contractName === "flatefee" ? (
                                    <th>Client Collections</th>
                                ) : null}

                                {props?.data?.contractName === "Transaction Amount" ||
                                    props?.data?.contractName === "FTE Amount" ? (
                                    <>
                                        <th>Transactions</th>
                                        <th>FTE Deployed</th>
                                    </>
                                ) : null}

                                <th>{props?.data?.contractName}</th>
                                <th>Invoice Amount</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {DayWiseTimeSheetList.map((item, index) => {
                                const isWeekend =
                                    item.daysWise === "Saturday" || item.daysWise === "Sunday";

                                return (
                                    <tr
                                        key={index}
                                        style={isWeekend ? { backgroundColor: "#d3d3d3" } : {}}
                                    >
                                        <td>{item.daysWise}</td>
                                        {props?.data?.contractName === "Transaction Amount" ||
                                            props?.data?.contractName === "FTE Amount" ? (
                                            <>
                                                <td>
                                                    {item.id === editId && !isWeekend ? (
                                                        <Form.Control
                                                            type="number"
                                                            value={editedRow?.transactions || ""}
                                                            onChange={(e) =>
                                                                handleInputChange(e, "transactions")
                                                            }
                                                        />
                                                    ) : (
                                                        item.transactions
                                                    )}
                                                </td>
                                                <td>
                                                    <Form.Control
                                                        type="number"
                                                        value={
                                                            item.id === editId
                                                                ? editedRow?.fteDeployed
                                                                : item?.fteDeployed
                                                        }
                                                        disabled
                                                    />
                                                </td>
                                            </>
                                        ) : null}

                                        {props?.data?.contractName === "Contingency Amount" ||
                                            props?.data?.contractName === "flatefee" ? (
                                            <td>
                                                {item.id === editId && !isWeekend ? (
                                                    <Form.Control
                                                        type="number"
                                                        value={editedRow?.clientCollections || ""}
                                                        onChange={(e) =>
                                                            handleInputChange(e, "clientCollections")
                                                        }
                                                    />
                                                ) : (
                                                    item.clientCollections
                                                )}
                                            </td>
                                        ) : null}

                                        <td>
                                            <Form.Control
                                                type="text"
                                                value={amount || "2"}
                                                disabled
                                            />
                                        </td>
                                        <td>
                                            <Form.Control
                                                type="number"
                                                value={
                                                    item.id === editId
                                                        ? editedRow?.invoiceAmount
                                                        : item?.invoiceAmount
                                                }
                                                disabled
                                            />
                                        </td>
                                        <td>
                                            {item.id === editId ? (
                                                <Button
                                                    variant="success"
                                                    onClick={handleSaveClick}
                                                    disabled={isWeekend}
                                                >
                                                    Save
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="primary"
                                                    className="btn-icon"
                                                    onClick={() => handleEditClick(item)}
                                                    disabled={isWeekend}
                                                >
                                                    <i className="ri-pencil-fill"></i>
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Total</th>
                                {props?.data?.contractName === "Transaction Amount" ||
                                    props?.data?.contractName === "FTE Amount" ? (
                                    <>
                                        <th>
                                            {DayWiseTimeSheetList.reduce(
                                                (acc, item) => acc + (item?.transactions || 0),
                                                0
                                            )}
                                        </th>
                                        <th>
                                            {DayWiseTimeSheetList.reduce(
                                                (acc, item) =>
                                                    acc + (Math.round(item?.fteDeployed) || 0),
                                                0
                                            )}
                                        </th>
                                    </>
                                ) : null}

                                {props?.data?.contractName === "Contingency Amount" ||
                                    props?.data?.contractName === "flatefee" ? (
                                    <th>
                                        {DayWiseTimeSheetList.reduce(
                                            (acc, item) =>
                                                acc + (Math.round(item?.clientCollections) || 0),
                                            0
                                        )}
                                    </th>
                                ) : null}

                                <th>
                                    {DayWiseTimeSheetList.reduce(
                                        (acc) => acc + (Math.round(amount) || 0),
                                        0
                                    )}
                                </th>
                                <th>
                                    {DayWiseTimeSheetList.reduce(
                                        (acc, item) => acc + (Math.round(item?.invoiceAmount) || 0),
                                        0
                                    )}
                                </th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </Table>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-between">
                    <div className="row">
                        <label>
                            Operation Remarks :
                            <Form.Control
                                as="textarea"
                                className="wt-500"
                                placeholder="Operation Remark.."
                                value={props?.data?.operationsRemarks || ''}
                                disabled
                            />
                        </label>
                    </div>
                    <div className="row">
                        <label>
                            Finance Remarks :
                            <Form.Control
                                as="textarea"
                                className="wt-500"
                                placeholder="Finance Remark.."
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className="d-flex gap-2">
                        <Button
                            type="button"
                            className="text-uppercase"
                            variant="outline-danger"
                            onClick={handleReject}
                        >
                            Reject
                        </Button>
                        <Button
                            type="button"
                            className="text-uppercase"
                            variant="outline-primary"
                            onClick={handleSubmit}
                        >
                            Approve
                        </Button>
                    </div>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
}
