export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS";
export const GET_ALL_USERGROUP = "GET_ALL_USERGROUP";
export const GET_ALL_USERGROUP_SUCCESS = "GET_ALL_USERGROUP_SUCCESS";
export const GET_ALL_USERDIRECTORY = "GET_ALL_USERDIRECTORY";
export const GET_ALL_USERDIRECTORY_SUCCESS = "GET_ALL_USERDIRECTORY_SUCCESS";
export const ADD_USER_GROUP = "ADD_USER_GROUP";
export const ADD_USER_GROUP_SUCCESS = "ADD_USER_GROUP_SUCCESS";
export const ADD_USER_DIRECTORY = "ADD_USER_DIRECTORY";
export const ADD_USER_DIRECTORY_SUCCESS = "ADD_USER_DIRECTORY_SUCCESS";
export const GET_USERS_LIST_BY_ID = "GET_USERS_LIST_BY_ID";
export const GET_USERS_LIST_BY_ID_SUCCESS = "GET_USERS_LIST_BY_ID_SUCCESS";
export const GET_USERS_LIST_BY_ID_SUCCESS_ID_2 = 'GET_USERS_LIST_BY_ID_SUCCESS_ID_2';
export const GET_USERS_LIST_BY_ID_SUCCESS_ID_6 = 'GET_USERS_LIST_BY_ID_SUCCESS_ID_6';
export const GET_USERS_LIST_BY_ID_SUCCESS_ID_3 = 'GET_USERS_LIST_BY_ID_SUCCESS_ID_3';

export const GetRole = {
  type: GET_ALL_ROLES
};

export const GetAllRolesSuccess = (data) => ({
  type: GET_ALL_ROLES_SUCCESS,
  payload: data
});

export const GetUserGroup = {
  type: GET_ALL_USERGROUP
};

export const GetAllUserGroupSuccess = (data) => ({
  type: GET_ALL_USERGROUP_SUCCESS,
  payload: data
});

export const GetUserDirectory = {
  type: GET_ALL_USERDIRECTORY
};

export const GetAllUserDirectorySuccess = (data) => ({
  type: GET_ALL_USERDIRECTORY_SUCCESS,
  payload: data
});

export const AddUserDirectory = (data)=>({
  type: ADD_USER_DIRECTORY,
  payload:data
});

export const AddUserDirectorySuccess  = (data) => ({
  type: ADD_USER_DIRECTORY_SUCCESS,
  payload: data
});
export const GetUsersListById = (data) => ({
  type: GET_USERS_LIST_BY_ID,
  payload: data
});
export const GetUsersListByIdSuccess = (data) => ({
  type: GET_USERS_LIST_BY_ID_SUCCESS,
  payload: data
});

